import React, { useState, useEffect, useCallback, useContext } from 'react';
import { UserContext } from '../../UserContext';
import { Plus, Minus, Check, X } from 'react-feather';
import { fetchAvailableAddOns, fetchUserAddOns, subscribeToAddOn, unsubscribeFromAddOn } from './BillingUtils';
import './billing.css';

const AddOns = ({ onError }) => {
  const { loadUserAddOns } = useContext(UserContext);
  const [availableAddOns, setAvailableAddOns] = useState([]);
  const [userAddOns, setUserAddOns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [quantities, setQuantities] = useState({});

  const loadAddOns = useCallback(async () => {
    try {
      setLoading(true);
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('access_token');

      // Fetch available add-ons
      const availableData = await fetchAvailableAddOns(token);
      if (availableData) {
        setAvailableAddOns(availableData);
      }

      // Fetch user's subscribed add-ons
      const userData = await fetchUserAddOns(userId, token);
      if (userData) {
        setUserAddOns(userData);
        
        // Initialize quantities based on user's current subscriptions
        const initialQuantities = {};
        userData.forEach(addon => {
          initialQuantities[addon.addon_id] = addon.quantity;
        });
        setQuantities(initialQuantities);
      }
    } catch (err) {
      console.error('Error loading add-ons:', err);
      onError && onError('Failed to load add-ons');
    } finally {
      setLoading(false);
    }
  }, [onError]);

  useEffect(() => {
    loadAddOns();
  }, [loadAddOns]);


  const handleQuantityChange = (addonId, change) => {
    setQuantities(prev => {
      const currentQuantity = prev[addonId] || 1;
      const newQuantity = Math.max(1, currentQuantity + change);
      return { ...prev, [addonId]: newQuantity };
    });
  };

  const handleSubscribe = async (addonId) => {
    try {
      setActionLoading(true);
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('access_token');
      const quantity = quantities[addonId] || 1;

      await subscribeToAddOn(userId, addonId, quantity, token);
      await loadAddOns(); // Reload add-ons after subscription
      await loadUserAddOns(); // Update user context with new add-ons
    } catch (err) {
      console.error('Error subscribing to add-on:', err);
      onError && onError('Failed to subscribe to add-on');
    } finally {
      setActionLoading(false);
    }
  };

  const handleUnsubscribe = async (addonId) => {
    try {
      setActionLoading(true);
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('access_token');

      await unsubscribeFromAddOn(userId, addonId, token);
      await loadAddOns(); // Reload add-ons after unsubscription
      await loadUserAddOns(); // Update user context with new add-ons
    } catch (err) {
      console.error('Error unsubscribing from add-on:', err);
      onError && onError('Failed to unsubscribe from add-on');
    } finally {
      setActionLoading(false);
    }
  };

  const isSubscribed = (addonId) => {
    return userAddOns.some(userAddon => userAddon.addon_id === addonId);
  };

  if (loading) {
    return <div className="loading-container">Loading add-ons...</div>;
  }

  return (
    <div className="addons-container">
      {availableAddOns.length === 0 ? (
        <div className="no-addons-message">
          No add-ons are currently available.
        </div>
      ) : (
        availableAddOns.map(addon => {
          const subscribed = isSubscribed(addon.id);
          
          return (
            <div key={addon.id} className={`addon-card ${subscribed ? 'subscribed' : ''}`}>
              <div className="addon-header">
                <h3>{addon.name}</h3>
                {subscribed && (
                  <span className="subscription-badge">
                    <Check size={16} /> Subscribed
                  </span>
                )}
              </div>
              
              <p className="addon-description">{addon.description}</p>
              
              <div className="addon-price">
                ${addon.price.toFixed(2)} / {addon.frequency === 'one_time' ? 'one-time' : 'month'}
              </div>
              
              {!subscribed ? (
                <div className="addon-actions">
                  <div className="quantity-selector">
                    <button 
                      className="quantity-btn"
                      onClick={() => handleQuantityChange(addon.id, -1)}
                      disabled={quantities[addon.id] <= 1}
                    >
                      <Minus size={16} />
                    </button>
                    <span className="quantity-value">{quantities[addon.id] || 1}</span>
                    <button 
                      className="quantity-btn"
                      onClick={() => handleQuantityChange(addon.id, 1)}
                    >
                      <Plus size={16} />
                    </button>
                  </div>
                  
                  <button 
                    className="subscribe-btn"
                    onClick={() => handleSubscribe(addon.id)}
                    disabled={actionLoading}
                  >
                    <Plus size={16} /> Subscribe
                  </button>
                </div>
              ) : (
                <div className="addon-actions">
                  <div className="quantity-info">
                    Quantity: {userAddOns.find(ua => ua.addon_id === addon.id)?.quantity || 1}
                  </div>
                  
                  <button 
                    className="unsubscribe-btn"
                    onClick={() => handleUnsubscribe(addon.id)}
                    disabled={actionLoading}
                  >
                    <X size={16} /> Unsubscribe
                  </button>
                </div>
              )}
            </div>
          );
        })
      )}
    </div>
  );
};

export default AddOns;
