import { API_BASE_URL, getApiUrl } from '../../config/api';

export const fetchDashboardData = async (accessToken, userId) => {
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  };

  try {
    // Debug URL construction
    console.log('=== Debug Info ===');
    console.log('API_BASE_URL:', API_BASE_URL);
    console.log('NODE_ENV:', process.env.NODE_ENV);
    console.log('Window Location:', window.location.href);
    console.log('Protocol:', window.location.protocol);
    
    const baseUrl = getApiUrl('');
    console.log('Final Base URL:', baseUrl);
    
    const endpoints = [
      '/api/user-profile',
      '/api/logical-assistants/list',
      '/api/usage',
      '/api/credits/balance',
      '/api/analysis'
    ];

    console.log('\n=== Dashboard API Calls ===');
    console.log('Headers:', headers);
    
    const requests = endpoints.map(endpoint => {
      const url = `${baseUrl}${endpoint}`;
      console.log(`\nMaking request to: ${url}`);
      console.log('Method: GET');
      console.log('Headers:', headers);
      
      return fetch(url, { headers })
        .then(async response => {
          console.log(`\nResponse for ${endpoint}:`);
          console.log('Status:', response.status);
          console.log('Headers:', Object.fromEntries(response.headers.entries()));
          if (!response.ok) {
            const text = await response.text();
            console.error('Error response:', text);
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response;
        })
        .catch(error => {
          console.error(`Error fetching ${endpoint}:`, error);
          console.error('Stack:', error.stack);
          throw error;
        });
    });

    const [profileResponse, assistantsResponse, usageResponse, creditsResponse, analysisResponse] = await Promise.all(requests);

    const [profileData, assistants, usageData, creditsData, analysisData] = await Promise.all([
      profileResponse.json(),
      assistantsResponse.json(),
      usageResponse.json(),
      creditsResponse.json(),
      analysisResponse.json()
    ]);

    return {
      business_name: profileData.business_name || 'Business Name Not Found',
      phone_number: (() => {
        const phoneSettings = assistants?.assistants?.[0]?.channel_settings?.find(cs => cs.channel_id === 1);
        if (phoneSettings && phoneSettings.settings) {
          // Check if settings is a string and parse it if needed
          const settingsObj = typeof phoneSettings.settings === 'string' 
            ? JSON.parse(phoneSettings.settings) 
            : phoneSettings.settings;
          return settingsObj.phone_number || 'Phone Number Not Assigned';
        }
        return 'Phone Number Not Assigned';
      })(),
      credit_balance: creditsData.balance || 0,
      subscription_status: profileData.subscription_status || 'trial',
      trial_end_date: profileData.trial_end || null,
      recent_usage: usageData.recent_usage || [],
      usage_stats: usageData.usage_stats || {
        total_calls: 0,
        total_chats: 0,
        total_duration: 0,
        total_customers: 0
      },
      analysis: analysisData || {
        daily: {
          total_interactions: 0,
          avg_duration: 0,
          sentiment_score: 0,
          ai_leads: 0,
          ai_closed_deals: 0,
          ai_prospects: 0
        },
        weekly: {
          total_interactions: 0,
          avg_duration: 0,
          sentiment_score: 0,
          ai_leads: 0,
          ai_closed_deals: 0,
          ai_prospects: 0
        },
        monthly: {
          total_interactions: 0,
          avg_duration: 0,
          sentiment_score: 0,
          ai_leads: 0,
          ai_closed_deals: 0,
          ai_prospects: 0
        }
      }
    };
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
    throw error;
  }
};

export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

export const formatDuration = (duration) => {
  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;
  return `${minutes}m ${seconds}s`;
};

export const getSentimentEmoji = (score) => {
  if (score >= 0.8) return '😊';
  if (score >= 0.6) return '🙂';
  return '😐';
};
