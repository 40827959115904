import React, { useState } from 'react';
import { Card, CardContent, CardHeader, IconButton, Collapse, Typography, Box } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import GeneralSettings from './sections/GeneralSettings';
import ChannelSettings from './sections/ChannelSettings';
import KnowledgeSettings from './sections/KnowledgeSettings';
import ToolsSettings from './sections/ToolsSettings';

const AssistantCard = ({ assistant, onUpdate }) => {
  const [expanded, setExpanded] = useState(false);
  const [activeSection, setActiveSection] = useState('general');

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const renderSection = () => {
    switch (activeSection) {
      case 'general':
        return <GeneralSettings assistant={assistant} onUpdate={onUpdate} />;
      case 'channels':
        return <ChannelSettings assistant={assistant} onUpdate={onUpdate} />;
      case 'knowledge':
        return <KnowledgeSettings assistant={assistant} onUpdate={onUpdate} />;
      case 'tools':
        return <ToolsSettings assistant={assistant} onUpdate={onUpdate} />;
      default:
        return null;
    }
  };

  return (
    <Card sx={{ 
      width: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      '& .MuiCardContent-root': {
        padding: '16px 24px',
        width: '100%',
        maxWidth: '100%'
      }
    }}>
      <CardHeader
        title={assistant.name}
        action={
          <IconButton
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        }
      />
      <CardContent sx={{ flex: 1 }}>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          {assistant.base_prompt ? assistant.base_prompt.substring(0, 100) + '...' : 'No prompt set'}
        </Typography>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Box sx={{ 
              display: 'flex', 
              flexWrap: 'wrap',
              gap: { xs: 1, sm: 2, md: 3 }
            }}>
              <Typography
                component="button"
                onClick={() => handleSectionChange('general')}
                sx={{
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                  color: activeSection === 'general' ? 'primary.main' : 'text.secondary',
                  fontWeight: activeSection === 'general' ? 'bold' : 'normal',
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  pb: 1,
                }}
              >
                General
              </Typography>
              <Typography
                component="button"
                onClick={() => handleSectionChange('channels')}
                sx={{
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                  color: activeSection === 'channels' ? 'primary.main' : 'text.secondary',
                  fontWeight: activeSection === 'channels' ? 'bold' : 'normal',
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  pb: 1,
                }}
              >
                Channels
              </Typography>
              <Typography
                component="button"
                onClick={() => handleSectionChange('knowledge')}
                sx={{
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                  color: activeSection === 'knowledge' ? 'primary.main' : 'text.secondary',
                  fontWeight: activeSection === 'knowledge' ? 'bold' : 'normal',
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  pb: 1,
                }}
              >
                Knowledge
              </Typography>
              <Typography
                component="button"
                onClick={() => handleSectionChange('tools')}
                sx={{
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                  color: activeSection === 'tools' ? 'primary.main' : 'text.secondary',
                  fontWeight: activeSection === 'tools' ? 'bold' : 'normal',
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  pb: 1,
                }}
              >
                Tools
              </Typography>
            </Box>
          </Box>
          {renderSection()}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default AssistantCard;
