import React, { useState, useEffect, useCallback } from 'react';
import { 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Typography, 
  TextField, 
  Switch, 
  Box, 
  FormControlLabel, 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Alert,
  Card,
  CardContent,
  CardActions,
  Divider,
  Paper,
  Grid,
  Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { API_BASE_URL } from '../../../config/api';

// Component for managing prompt buttons within an ad-specific message
const PromptButtonEditor = ({ buttons = [], onChange, maxButtons = 2 }) => {
  const handleButtonChange = (index, field, value) => {
    const updatedButtons = [...buttons];
    if (!updatedButtons[index]) {
      updatedButtons[index] = { button_text: '', prompt: '' };
    }
    updatedButtons[index][field] = value;
    onChange(updatedButtons);
  };

  const addButton = () => {
    if (buttons.length < maxButtons) {
      onChange([...buttons, { button_text: '', prompt: '' }]);
    }
  };

  const removeButton = (index) => {
    const updatedButtons = [...buttons];
    updatedButtons.splice(index, 1);
    onChange(updatedButtons);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Prompt Buttons (max {maxButtons})
      </Typography>
      
      {buttons.map((button, index) => (
        <Card key={index} variant="outlined" sx={{ mb: 2, p: 1 }}>
          <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Button Text"
                  value={button.button_text || ''}
                  onChange={(e) => handleButtonChange(index, 'button_text', e.target.value)}
                  fullWidth
                  size="small"
                  placeholder="e.g., Learn More"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Prompt"
                  value={button.prompt || ''}
                  onChange={(e) => handleButtonChange(index, 'prompt', e.target.value)}
                  fullWidth
                  size="small"
                  placeholder="e.g., Tell me more about your product"
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end', p: 1 }}>
            <IconButton 
              size="small" 
              color="error" 
              onClick={() => removeButton(index)}
              aria-label="Remove button"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </CardActions>
        </Card>
      ))}
      
      {buttons.length < maxButtons && (
        <Button
          startIcon={<AddIcon />}
          onClick={addButton}
          variant="outlined"
          size="small"
          sx={{ mt: 1 }}
        >
          Add Prompt Button
        </Button>
      )}
    </Box>
  );
};

// Component for managing ad-specific messages
const AdSpecificMessageEditor = ({ messages = [], onChange }) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(null);
  const [currentMessage, setCurrentMessage] = useState({ ad_id: '', message: '', prompt_buttons: [] });

  const openEditDialog = (index) => {
    if (index !== null && messages[index]) {
      setCurrentMessage({...messages[index]});
    } else {
      setCurrentMessage({ ad_id: '', message: '', prompt_buttons: [] });
    }
    setCurrentMessageIndex(index);
    setEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
    setCurrentMessageIndex(null);
  };

  const saveMessage = () => {
    const updatedMessages = [...messages];
    if (currentMessageIndex !== null && currentMessageIndex < messages.length) {
      // Edit existing message
      updatedMessages[currentMessageIndex] = currentMessage;
    } else {
      // Add new message
      updatedMessages.push(currentMessage);
    }
    onChange(updatedMessages);
    closeEditDialog();
  };

  const deleteMessage = (index) => {
    const updatedMessages = [...messages];
    updatedMessages.splice(index, 1);
    onChange(updatedMessages);
  };

  return (
    <>
      <Box sx={{ mt: 3, mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">
            Ad-specific Messages
            <Tooltip title="Create custom messages for specific ad campaigns. Markdown formatting is supported for rich text.">
              <IconButton size="small" sx={{ ml: 1 }}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() => openEditDialog(null)}
          >
            Add Message
          </Button>
        </Box>

        {messages.length === 0 ? (
          <Paper sx={{ p: 3, textAlign: 'center', bgcolor: 'background.paper' }}>
            <Typography color="text.secondary">
              No ad-specific messages configured. Add a message to get started.
            </Typography>
          </Paper>
        ) : (
          messages.map((msg, index) => (
            <Card key={index} variant="outlined" sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Ad ID: {msg.ad_id}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, whiteSpace: 'pre-wrap' }}>
                  {msg.message}
                </Typography>
                
                {msg.prompt_buttons && msg.prompt_buttons.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2">Prompt Buttons:</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                      {msg.prompt_buttons.map((btn, btnIndex) => (
                        <Tooltip key={btnIndex} title={`Prompt: ${btn.prompt}`}>
                          <Button size="small" variant="outlined">
                            {btn.button_text}
                          </Button>
                        </Tooltip>
                      ))}
                    </Box>
                  </Box>
                )}
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                  startIcon={<EditIcon />}
                  size="small"
                  onClick={() => openEditDialog(index)}
                >
                  Edit
                </Button>
                <Button
                  startIcon={<DeleteIcon />}
                  size="small"
                  color="error"
                  onClick={() => deleteMessage(index)}
                >
                  Delete
                </Button>
              </CardActions>
            </Card>
          ))
        )}
      </Box>

      {/* Edit Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={closeEditDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {currentMessageIndex !== null ? 'Edit Ad-specific Message' : 'Add Ad-specific Message'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
            <TextField
              label="Ad ID"
              value={currentMessage.ad_id || ''}
              onChange={(e) => setCurrentMessage({...currentMessage, ad_id: e.target.value})}
              fullWidth
              placeholder="e.g., campaign_123"
              helperText="Unique identifier for the ad campaign"
            />
            
            <TextField
              label="Message"
              value={currentMessage.message || ''}
              onChange={(e) => setCurrentMessage({...currentMessage, message: e.target.value})}
              fullWidth
              multiline
              rows={6}
              placeholder="Enter your message here. Markdown formatting is supported."
              helperText="This message will be shown when a user arrives from this ad. Markdown formatting is supported for rich text."
            />
            
            <PromptButtonEditor
              buttons={currentMessage.prompt_buttons || []}
              onChange={(buttons) => setCurrentMessage({...currentMessage, prompt_buttons: buttons})}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditDialog}>Cancel</Button>
          <Button 
            onClick={saveMessage} 
            variant="contained" 
            color="primary"
            disabled={!currentMessage.ad_id || !currentMessage.message}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const TextFieldWithModal = ({ label, value, onChange, multiline = true, rows = 3, readOnly = false }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, width: '100%' }}>
        <TextField
          label={label}
          value={value || ''}
          onChange={onChange}
          fullWidth
          multiline={multiline}
          rows={rows}
          InputProps={{ 
            readOnly,
            endAdornment: !readOnly && (
              <IconButton 
                onClick={() => setOpen(true)}
                sx={{ position: 'absolute', right: 8, top: 8 }}
              >
                <OpenInFullIcon />
              </IconButton>
            )
          }}
        />
      </Box>
      <Dialog 
        open={open} 
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">{label}</Typography>
          <IconButton onClick={() => setOpen(false)} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            value={value || ''}
            onChange={onChange}
            fullWidth
            multiline
            rows={15}
            variant="outlined"
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ChannelSettings = ({ assistant, onUpdate }) => {
  const [channels, setChannels] = useState([]);
  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState({});
  const [error, setError] = useState({});
  const [success, setSuccess] = useState({});

  const fetchChannelSettings = useCallback(async () => {
    try {
      const [channelsResponse, settingsResponse] = await Promise.all([
        fetch(`${API_BASE_URL}/api/logical-assistants/channels`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
          }
        }),
        fetch(`${API_BASE_URL}/api/logical-assistants/${assistant.id}/channel-settings`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
          }
        })
      ]);

      let channelsData, settingsData;

      try {
        if (!channelsResponse.ok) {
          const errorText = await channelsResponse.text();
          console.error('Channels response error:', errorText);
          const error = JSON.parse(errorText);
          throw new Error(error.detail || 'Failed to fetch channels');
        }
        channelsData = await channelsResponse.json();
      } catch (error) {
        console.error('Error parsing channels response:', error);
        channelsData = { channels: [] };
      }

      try {
        if (!settingsResponse.ok) {
          const errorText = await settingsResponse.text();
          console.error('Settings response error:', errorText);
          const error = JSON.parse(errorText);
          throw new Error(error.detail || 'Failed to fetch settings');
        }
        settingsData = await settingsResponse.json();
      } catch (error) {
        console.error('Error parsing settings response:', error);
        settingsData = {};
      }

      setChannels(channelsData.channels);
      setSettings(settingsData);
    } catch (error) {
      console.error('Error fetching channel settings:', error);
    } finally {
      setLoading(false);
    }
  }, [assistant.id]);

  useEffect(() => {
    fetchChannelSettings();
  }, [fetchChannelSettings]);

  const handleSettingChange = async (channelId, settingData) => {
    try {
      setSaving(prevSaving => ({ ...prevSaving, [channelId]: true }));
      setError(prevError => ({ ...prevError, [channelId]: '' }));
      
      const response = await fetch(`${API_BASE_URL}/api/logical-assistants/${assistant.id}/channels/${channelId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(settingData)
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Server response:', response.status, errorText);
        setError(prevError => ({ ...prevError, [channelId]: errorText || `Server error: ${response.status}` }));
        throw new Error(`Server error: ${response.status}`);
      }

      await response.json();
      setSuccess(prevSuccess => ({ ...prevSuccess, [channelId]: 'Settings saved successfully' }));
      
      // Clear success message after a delay
      setTimeout(() => {
        setSuccess(prevSuccess => ({ ...prevSuccess, [channelId]: '' }));
      }, 3000);
      
      onUpdate();
    } catch (error) {
      console.error('Error updating channel settings:', error);
      if (!error.message.includes('Server error')) {
        setError(prevError => ({ ...prevError, [channelId]: 'Failed to update channel settings' }));
      }
    } finally {
      setSaving(prevSaving => ({ ...prevSaving, [channelId]: false }));
    }
  };

  const [unsavedChanges, setUnsavedChanges] = useState({});

  const handleLocalChange = (channelId, changes) => {
    setUnsavedChanges(prev => ({
      ...prev,
      [channelId]: {
        ...(prev[channelId] || {}),
        ...changes
      }
    }));
  };

  const handleSave = async (channelId) => {
    if (unsavedChanges[channelId]) {
      const currentSettings = settings[channelId] || {};
      const updatedSettings = {
        ...currentSettings,
        ...unsavedChanges[channelId]
      };
      
      await handleSettingChange(channelId, updatedSettings);
      
      // Update local settings state with saved changes
      setSettings(prev => ({
        ...prev,
        [channelId]: updatedSettings
      }));
      
      // Clear unsaved changes
      setUnsavedChanges(prev => {
        const newState = { ...prev };
        delete newState[channelId];
        return newState;
      });
    }
  };

  const renderChannelSettings = (channel) => {
    const channelSettings = settings[channel.id] || {};
    const pendingChanges = unsavedChanges[channel.id] || {};
    const currentSettings = { 
      ...channelSettings, 
      ...pendingChanges,
      channel_type: channel.name // Ensure channel_type is set
    };

    switch (channel.name) {
      case 'phone':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {error[channel.id] && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error[channel.id]}
              </Alert>
            )}
            
            {success[channel.id] && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {success[channel.id]}
              </Alert>
            )}
            <TextField
              label="Phone Number"
              value={currentSettings.phone_number || ''}
              onChange={(e) => handleLocalChange(channel.id, { 
                phone_number: e.target.value 
              })}
              fullWidth
            />
            <TextField
              label="Call Forward Number"
              value={currentSettings.call_forward_number || ''}
              onChange={(e) => handleLocalChange(channel.id, {
                call_forward_number: e.target.value
              })}
              fullWidth
            />
            <TextFieldWithModal
              label="Channel Instructions"
              value={currentSettings.channel_instructions || currentSettings.voice_channel_instructions || ''}
              onChange={(e) => handleLocalChange(channel.id, {
                channel_instructions: e.target.value
              })}
            />
            <TextFieldWithModal
              label="Initial Greeting"
              value={currentSettings.initial_greeting || ''}
              onChange={(e) => handleLocalChange(channel.id, {
                initial_greeting: e.target.value
              })}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={currentSettings.send_call_summaries_sms || false}
                  onChange={(e) => handleLocalChange(channel.id, {
                    send_call_summaries_sms: e.target.checked
                  })}
                />
              }
              label="Send Call Summaries via SMS"
            />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => handleSave(channel.id)}
              disabled={!unsavedChanges[channel.id] || saving[channel.id]}
              sx={{ alignSelf: 'flex-end', mt: 2 }}
            >
              {saving[channel.id] ? 'Saving...' : 'Save Channel Settings'}
            </Button>
          </Box>
        );

      case 'web':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {error[channel.id] && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error[channel.id]}
              </Alert>
            )}
            
            {success[channel.id] && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {success[channel.id]}
              </Alert>
            )}
            <TextField
              label="API Key"
              value={currentSettings.api_key || 'Auto-generated'}
              InputProps={{ readOnly: true }}
              fullWidth
            />
            <TextField
              label="Embed Code"
              value={currentSettings.embed_code || 'Generated after saving'}
              InputProps={{ readOnly: true }}
              fullWidth
              multiline
              rows={3}
            />
            <TextFieldWithModal
              label="Initial Greeting"
              value={currentSettings.initial_greeting || ''}
              onChange={(e) => handleLocalChange(channel.id, {
                initial_greeting: e.target.value
              })}
            />
            <TextFieldWithModal
              label="Channel Instructions"
              value={currentSettings.channel_instructions || currentSettings.web_channel_instructions || ''}
              onChange={(e) => handleLocalChange(channel.id, {
                channel_instructions: e.target.value
              })}
            />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => handleSave(channel.id)}
              disabled={!unsavedChanges[channel.id] || saving[channel.id]}
              sx={{ alignSelf: 'flex-end', mt: 2 }}
            >
              {saving[channel.id] ? 'Saving...' : 'Save Channel Settings'}
            </Button>
          </Box>
        );

      case 'Dynamic_Landing_Page':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {error[channel.id] && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error[channel.id]}
              </Alert>
            )}
            
            {success[channel.id] && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {success[channel.id]}
              </Alert>
            )}
            <TextField
              label="API Key"
              value={currentSettings.api_key || 'Auto-generated'}
              InputProps={{ readOnly: true }}
              fullWidth
            />
            <TextField
              label="Embed Code"
              value={currentSettings.embed_code || 'Generated after saving'}
              InputProps={{ readOnly: true }}
              fullWidth
              multiline
              rows={3}
            />
            <TextFieldWithModal
              label="Channel Instructions"
              value={currentSettings.channel_instructions || ''}
              onChange={(e) => handleLocalChange(channel.id, {
                channel_instructions: e.target.value
              })}
            />
            <TextFieldWithModal
              label="Initial Greeting"
              value={currentSettings.initial_greeting || ''}
              onChange={(e) => handleLocalChange(channel.id, {
                initial_greeting: e.target.value
              })}
            />
            
            <Divider sx={{ my: 2 }} />
            
            {/* Ad-specific Messages Editor */}
            <AdSpecificMessageEditor
              messages={currentSettings.ad_specific_messages || []}
              onChange={(messages) => handleLocalChange(channel.id, {
                ad_specific_messages: messages
              })}
            />
            
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => handleSave(channel.id)}
              disabled={!unsavedChanges[channel.id] || saving[channel.id]}
              sx={{ alignSelf: 'flex-end', mt: 2 }}
            >
              {saving[channel.id] ? 'Saving...' : 'Save Channel Settings'}
            </Button>
          </Box>
        );
        
      default:
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {error[channel.id] && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error[channel.id]}
              </Alert>
            )}
            
            {success[channel.id] && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {success[channel.id]}
              </Alert>
            )}
            <TextFieldWithModal
              label="Channel Instructions"
              value={currentSettings.channel_instructions || ''}
              onChange={(e) => handleLocalChange(channel.id, {
                channel_instructions: e.target.value
              })}
            />
            <TextFieldWithModal
              label="Initial Greeting"
              value={currentSettings.initial_greeting || ''}
              onChange={(e) => handleLocalChange(channel.id, {
                initial_greeting: e.target.value
              })}
            />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => handleSave(channel.id)}
              disabled={!unsavedChanges[channel.id] || saving[channel.id]}
              sx={{ alignSelf: 'flex-end', mt: 2 }}
            >
              {saving[channel.id] ? 'Saving...' : 'Save Channel Settings'}
            </Button>
          </Box>
        );
    }
  };

  if (loading) {
    return <div>Loading channel settings...</div>;
  }

  // Filter channels to only show those that have settings for this assistant
  const assignedChannelIds = Object.keys(settings).map(id => parseInt(id, 10));
  const filteredChannels = channels.filter(channel => assignedChannelIds.includes(channel.id));

  return (
    <Box sx={{ width: '100%' }}>
      {filteredChannels.length === 0 ? (
        <Typography variant="body1" sx={{ p: 2 }}>
          No channels configured for this assistant.
        </Typography>
      ) : (
        filteredChannels.map((channel) => (
          <Accordion key={channel.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: 'medium', fontSize: '1.1rem' }}>
                {channel.name.charAt(0).toUpperCase() + channel.name.slice(1)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 3 }}>
              {renderChannelSettings(channel)}
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </Box>
  );
};

export default ChannelSettings;
