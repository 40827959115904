// Central configuration for API endpoints
// Ensure development always uses HTTP while production remains unchanged
export const API_BASE_URL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:8000' // Development URL - force HTTP
  : 'https://salesoptai2025-backend-ajc8hac0f6aqdvhw.canadacentral-01.azurewebsites.net'; // Production URL - keep HTTPS

// Helper to ensure we never convert to HTTPS in development
export const getApiUrl = (path) => {
  const baseUrl = API_BASE_URL;
  // Only in development, ensure we're using HTTP
  if (process.env.NODE_ENV === 'development') {
    return baseUrl.replace('https://', 'http://') + path;
  }
  return baseUrl + path;
};
