import { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext';
import LoadingSpinner from '../components/LoadingSpinner';

const CompleteSetup = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { setUserId, setUserEmail } = useContext(UserContext);
  const [status, setStatus] = useState('loading');
  const [message, setMessage] = useState('Setting up your demo account...');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    console.log('CompleteSetup component mounted or dependencies changed');
    console.log('Dependencies:', { userId, apiUrl });
    
    // Add a timestamp to track when the effect runs
    const effectTimestamp = new Date().toISOString();
    console.log(`Effect running at: ${effectTimestamp}`);
    
    const completeSetup = async () => {
      try {
        console.log(`Completing setup for demo user ID: ${userId}`);
        console.log(`API URL: ${apiUrl}/api/signup/complete-setup/${userId}`);
        
        // Add a timestamp before the API call
        const apiCallTimestamp = new Date().toISOString();
        console.log(`API call starting at: ${apiCallTimestamp}`);
        
        const response = await fetch(`${apiUrl}/api/signup/complete-setup/${userId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        // Add a timestamp after the API call
        const apiResponseTimestamp = new Date().toISOString();
        console.log(`API response received at: ${apiResponseTimestamp}`);

        const data = await response.json();
        console.log('Complete setup response:', data);

        if (response.ok && data.status === 'success') {
          // Store the access token in localStorage
          localStorage.setItem('access_token', data.access_token);
          
          // Update user context
          if (data.user_email) {
            setUserEmail(data.user_email);
          }
          setUserId(userId);
          
          setStatus('success');
          setMessage('Account setup completed successfully! Redirecting to dashboard...');
          
          // Redirect to dashboard after a short delay
          setTimeout(() => {
            navigate('/dashboard', { replace: true });
          }, 2000);
        } else {
          setStatus('error');
          setMessage(data.detail || 'Failed to complete account setup. Please contact support.');
        }
      } catch (error) {
        console.error('Error completing setup:', error);
        setStatus('error');
        setMessage('An unexpected error occurred. Please try again or contact support.');
      }
    };

    completeSetup();
    
    return () => {
      console.log('CompleteSetup component unmounted');
    };
  }, [userId, apiUrl, navigate, setUserId, setUserEmail]);

  return (
    <div className="complete-setup-container" style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center',
      minHeight: '100vh',
      padding: '20px',
      backgroundColor: 'var(--bg-color)',
      color: 'var(--text-color)'
    }}>
      <div style={{ 
        maxWidth: '500px', 
        width: '100%', 
        textAlign: 'center',
        padding: '30px',
        borderRadius: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
      }}>
        <img 
          src="/images/3.png" 
          alt="SalesOptAI" 
          style={{ height: 'auto', maxHeight: '100px', marginBottom: '20px' }} 
        />
        
        <h2 style={{ marginBottom: '20px' }}>Demo Account Setup</h2>
        
        {status === 'loading' && (
          <div style={{ marginBottom: '20px' }}>
            <LoadingSpinner />
          </div>
        )}
        
        <p style={{ 
          fontSize: '1.1rem', 
          marginBottom: '20px',
          color: status === 'error' ? 'var(--error-color)' : 'var(--text-color)'
        }}>
          {message}
        </p>
        
        {status === 'error' && (
          <button 
            onClick={() => navigate('/signup')} 
            style={{
              padding: '10px 20px',
              backgroundColor: 'var(--accent)',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '1rem'
            }}
          >
            Return to Signup
          </button>
        )}
      </div>
    </div>
  );
};

export default CompleteSetup;
