import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  Box, 
  IconButton, 
  Typography, 
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseIcon from '@mui/icons-material/Close';
import { API_BASE_URL } from '../../../config/api';

const TextFieldWithModal = ({ label, value, onChange, multiline = true, rows = 3 }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, width: '100%' }}>
        <TextField
          label={label}
          value={value || ''}
          onChange={onChange}
          fullWidth
          multiline={multiline}
          rows={rows}
          InputProps={{ 
            endAdornment: (
              <IconButton 
                onClick={() => setOpen(true)}
                sx={{ position: 'absolute', right: 8, top: 8 }}
              >
                <OpenInFullIcon />
              </IconButton>
            )
          }}
        />
      </Box>
      <Dialog 
        open={open} 
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">{label}</Typography>
          <IconButton onClick={() => setOpen(false)} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            value={value || ''}
            onChange={onChange}
            fullWidth
            multiline
            rows={15}
            variant="outlined"
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const GeneralSettings = ({ assistant, onUpdate }) => {
  const [name, setName] = useState(assistant.name);
  const [basePrompt, setBasePrompt] = useState(assistant.base_prompt || '');
  // Keep initialGreeting in state for backward compatibility
  const [initialGreeting] = useState(assistant.initial_greeting || '');
  const [notificationEmails, setNotificationEmails] = useState(assistant.notification_emails || []);
  const [notificationNumbers, setNotificationNumbers] = useState(assistant.notification_numbers || []);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    setError('');
    
    try {
      const response = await fetch(`${API_BASE_URL}/api/logical-assistants/${assistant.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name,
          base_prompt: basePrompt,
          initial_greeting: initialGreeting,
          notification_emails: notificationEmails.filter(email => email.trim()),
          notification_numbers: notificationNumbers.filter(number => number.trim())
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Server response:', response.status, errorText);
        setError(errorText);
        throw new Error(`Server error: ${response.status}`);
      }

      await response.json();
      onUpdate();
    } catch (error) {
      console.error('Error updating assistant:', error);
      if (!error.message.includes('Server error')) {
        setError('Failed to update assistant settings');
      }
    } finally {
      setSaving(false);
    }
  };

  const handleAddEmail = () => {
    setNotificationEmails([...notificationEmails, '']);
  };

  const handleAddNumber = () => {
    setNotificationNumbers([...notificationNumbers, '']);
  };

  const handleEmailChange = (index, value) => {
    const newEmails = [...notificationEmails];
    newEmails[index] = value;
    setNotificationEmails(newEmails);
  };

  const handleNumberChange = (index, value) => {
    const newNumbers = [...notificationNumbers];
    newNumbers[index] = value;
    setNotificationNumbers(newNumbers);
  };

  const handleRemoveEmail = (index) => {
    setNotificationEmails(notificationEmails.filter((_, i) => i !== index));
  };

  const handleRemoveNumber = (index) => {
    setNotificationNumbers(notificationNumbers.filter((_, i) => i !== index));
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      
      <TextField
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        required
      />
      
      <TextFieldWithModal
        label="Base Prompt"
        value={basePrompt}
        onChange={(e) => setBasePrompt(e.target.value)}
        rows={4}
      />
      
      {/* Notification Emails Section */}
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>Notification Emails</Typography>
        {notificationEmails.map((email, index) => (
          <Box key={index} sx={{ display: 'flex', gap: 1, mb: 1 }}>
            <TextField
              value={email}
              onChange={(e) => handleEmailChange(index, e.target.value)}
              placeholder="Enter email address"
              type="email"
              fullWidth
              size="small"
            />
            <IconButton 
              onClick={() => handleRemoveEmail(index)}
              color="error"
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        <Button 
          onClick={handleAddEmail}
          variant="outlined"
          size="small"
          sx={{ mt: 1 }}
        >
          Add Email
        </Button>
      </Box>

      {/* Notification Numbers Section */}
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>Notification Numbers</Typography>
        <Typography variant="caption" color="text.secondary" sx={{ mb: 2, display: 'block' }}>
          Format: +1 (123) 456-7890 or any format - numbers will be automatically formatted
        </Typography>
        {notificationNumbers.map((number, index) => (
          <Box key={index} sx={{ display: 'flex', gap: 1, mb: 1 }}>
            <TextField
              value={number}
              onChange={(e) => handleNumberChange(index, e.target.value)}
              placeholder="Enter phone number"
              type="tel"
              fullWidth
              size="small"
            />
            <IconButton 
              onClick={() => handleRemoveNumber(index)}
              color="error"
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        <Button 
          onClick={handleAddNumber}
          variant="outlined"
          size="small"
          sx={{ mt: 1 }}
        >
          Add Phone Number
        </Button>
      </Box>

      <Button 
        type="submit" 
        variant="contained" 
        disabled={saving}
        sx={{ alignSelf: 'flex-end', mt: 2 }}
      >
        {saving ? 'Saving...' : 'Save Changes'}
      </Button>
    </Box>
  );
};

export default GeneralSettings;
