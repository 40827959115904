import React from 'react';
import PageContainer from '../components/PageContainer';
import './leadflow/leadflow.css';

const LeadFlow = () => {
  return (
    <PageContainer
      title="LeadFlow"
      subtitle="Automated Lead Qualification and Routing"
    >
      <div className="coming-soon-container">
        <div className="coming-soon-card">
          <h2 className="coming-soon-title">Coming Soon</h2>
          <p className="coming-soon-text">
            We're working hard to bring you powerful lead qualification and routing capabilities.
            The LeadFlow feature will be available shortly.
          </p>
          <p className="coming-soon-text">
            Thank you for your patience as we develop this exciting new feature!
          </p>
        </div>
      </div>
    </PageContainer>
  );
};

export default LeadFlow;
