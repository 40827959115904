import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { SubscriptionSection } from '../components/signup';
import './SubscriptionSetup.css';

const SubscriptionSetup = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    // If no token is provided, redirect to signup
    if (!token) {
        return <Navigate to="/signup" replace />;
    }

    return (
        <div className="subscription-setup-page">
            <SubscriptionSection />
        </div>
    );
};

export default SubscriptionSetup;
