/* global helcimProcess */
import React, { useState, useEffect } from 'react';
import './SubscriptionSection.css';
import { initializeDebugProxies } from '../../utils/debug-proxy';

const SubscriptionSection = () => {
    const [selectedPlan, setSelectedPlan] = useState('monthly');
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loadingMessage, setLoadingMessage] = useState('');

    // Function to cycle through loading messages
    const cycleLoadingMessages = () => {
        const messages = [
            { text: "Verifying Card", delay: 0 },
            { text: "Setting Up Account", delay: 3000 },
            { text: "Finishing Up", delay: 6000 }
        ];

        messages.forEach(({ text, delay }) => {
            setTimeout(() => {
                setLoadingMessage(text);
            }, delay);
        });
    };

    // Function to update debug info in localStorage
    const updateDebugInfo = (info) => {
        const currentDebug = JSON.parse(localStorage.getItem('helcimDebugInfo') || '{}');
        const updatedDebug = {
            ...currentDebug,
            ...info,
            debugHistory: [
                ...(currentDebug.debugHistory || []),
                {
                    timestamp: new Date().toISOString(),
                    ...info
                }
            ]
        };
        localStorage.setItem('helcimDebugInfo', JSON.stringify(updatedDebug));
        return updatedDebug;
    };

    useEffect(() => {
        // Initialize debug proxies
        initializeDebugProxies();

        const initializeHelcim = () => {
            try {
                // Initialize debug info
                const debugInfo = updateDebugInfo({
                    type: 'initialization',
                    helcimJsUrl: process.env.REACT_APP_HELCIM_JS_URL,
                    helcimJsToken: process.env.REACT_APP_HELCIM_JS_TOKEN
                });
                
                console.error('=== Helcim.js Initialization Start ===');
                console.error('Debug Info:', debugInfo);

                // Remove any existing script
                const existingScript = document.querySelector('script[src*="helcim"]');
                if (existingScript) {
                    console.error('Removing existing Helcim script');
                    existingScript.remove();
                }

                // Create and append script to body
                const script = document.createElement('script');
                script.src = process.env.REACT_APP_HELCIM_JS_URL;
                script.type = 'text/javascript';
                script.async = false; // Ensure script loads synchronously

                // Add error event listener
                script.addEventListener('error', (error) => {
                    console.error('=== Helcim.js Script Load Error ===');
                    console.error('Error details:', error);
                    updateDebugInfo({
                        type: 'script_load_error',
                        error: {
                            message: error.message,
                            stack: error.stack
                        }
                    });
                });

                script.onload = () => {
                    console.error('=== Helcim.js Script Loaded ===');
                    
                    // Verify form configuration
                    const configToken = document.getElementById('token')?.value;
                    const helcimResults = document.getElementById('helcimResults');
                    
                    const formConfig = {
                        token: configToken || 'MISSING',
                        helcimResults: helcimResults ? 'FOUND' : 'MISSING',
                        timestamp: new Date().toISOString()
                    };
                    
                    console.error('Form configuration:', formConfig);
                    updateDebugInfo({
                        type: 'script_loaded',
                        formConfig
                    });
                    
                    // Verify helcimProcess is available
                    if (typeof window.helcimProcess !== 'function') {
                        const error = 'helcimProcess function not found after script load';
                        console.error('ERROR:', error);
                        updateDebugInfo({
                            type: 'missing_function',
                            error: {
                                message: error
                            }
                        });
                        setErrorMessage('Payment system not properly initialized. Please refresh the page.');
                    } else {
                        console.error('helcimProcess function available');
                        updateDebugInfo({
                            type: 'helcim_process_available'
                        });
                    }

                    if (!configToken || !helcimResults) {
                        const error = 'Required Helcim.js elements missing';
                        console.error('ERROR:', error);
                        updateDebugInfo({
                            type: 'missing_elements',
                            error: {
                                message: error
                            }
                        });
                        setErrorMessage('Payment form configuration error. Please refresh the page.');
                    }
                };

                script.onerror = () => {
                    const error = 'Failed to load Helcim.js';
                    console.error('=== Helcim.js Load Error ===');
                    console.error(error);
                    updateDebugInfo({
                        type: 'script_load_error',
                        error: {
                            message: error
                        }
                    });
                    setErrorMessage('Failed to load payment system. Please refresh the page.');
                };

                console.error('Appending Helcim script to body');
                document.body.appendChild(script);
            } catch (error) {
                console.error('=== Helcim.js Initialization Error ===');
                console.error('Error:', error);
                updateDebugInfo({
                    type: 'initialization_error',
                    error: {
                        message: error.message,
                        stack: error.stack
                    }
                });
                setErrorMessage('Error initializing payment system. Please refresh the page.');
            }
        };

        // Initialize Helcim
        initializeHelcim();

        return () => {
            console.error('=== Cleaning up Helcim.js ===');
            const script = document.querySelector('script[src*="helcim"]');
            if (script) {
                console.error('Removing Helcim script');
                script.remove();
            }
        };
    }, []);

    const handlePlanSelect = (plan) => {
        setSelectedPlan(plan);
    };

    // This function will be called by Helcim.js after processing
    window.helcimCallback = async (response) => {
        const callbackInfo = {
            timestamp: new Date().toISOString(),
            response: response,
            responseType: typeof response,
            responseKeys: Object.keys(response)
        };
        
        try {
            console.error('=== Helcim.js Callback ===');
            console.error('Callback Info:', callbackInfo);
            
            // Check for Helcim.js response status
            if (response.response !== "1" && response.response !== 1) {
                const error = response.responseMessage || 'Card verification failed';
                updateDebugInfo({
                    type: 'helcim_error',
                    error: {
                        message: error,
                        response
                    }
                });
                throw new Error(error);
            }

            // Get the helcimResults div
            const helcimResults = document.getElementById('helcimResults');
            if (!helcimResults) {
                throw new Error('Helcim results container not found');
            }

            // Log all hidden inputs in helcimResults
            console.error('=== Helcim Results ===');
            const hiddenInputs = helcimResults.querySelectorAll('input[type="hidden"]');
            const helcimData = {};
            hiddenInputs.forEach(input => {
                if (input.name === 'cardToken') {
                    helcimData[input.name] = input.value ? `${input.value.substring(0, 10)}...` : 'None';
                } else {
                    helcimData[input.name] = input.value;
                }
                console.error(`${input.name}: ${helcimData[input.name]}`);
            });

            updateDebugInfo({
                type: 'helcim_results',
                helcimData
            });

            // Check for successful verification
            if (helcimData.response !== "1" && helcimData.response !== 1) {
                throw new Error(helcimData.responseMessage || 'Card verification failed');
            }

            // Get the card token from helcimResults
            const cardTokenInput = helcimResults.querySelector('input[name="cardToken"]');
            if (!cardTokenInput || !cardTokenInput.value) {
                throw new Error('Card verification failed: No token received');
            }

            // Get the form
            const form = document.getElementById('helcimForm');
            if (!form) {
                throw new Error('Payment form not found. Please refresh the page.');
            }

            // Add or update the card token in the form
            console.error('Adding card token to form');
            let tokenInput = form.querySelector('input[name="card_token"]');
            if (!tokenInput) {
                console.error('Creating new card_token input');
                tokenInput = document.createElement('input');
                tokenInput.type = 'hidden';
                tokenInput.name = 'card_token';
                form.appendChild(tokenInput);
            }
            tokenInput.value = cardTokenInput.value;
            console.error('Card token added to form:', tokenInput.value ? `${tokenInput.value.substring(0, 10)}...` : 'None');

            // Also add a hidden input for cardToken to ensure both names are present
            let helcimTokenInput = form.querySelector('input[name="cardToken"]');
            if (!helcimTokenInput) {
                console.error('Creating new cardToken input');
                helcimTokenInput = document.createElement('input');
                helcimTokenInput.type = 'hidden';
                helcimTokenInput.name = 'cardToken';
                form.appendChild(helcimTokenInput);
            }
            helcimTokenInput.value = cardTokenInput.value;
            console.error('Helcim token added to form:', helcimTokenInput.value ? `${helcimTokenInput.value.substring(0, 10)}...` : 'None');

            updateDebugInfo({
                type: 'tokens_added',
                cardTokenLength: cardTokenInput.value.length,
                hasCardToken: !!cardTokenInput.value,
                hasHelcimToken: !!helcimTokenInput.value
            });

            // Resolve the Promise to signal token is ready
            if (window.helcimCallbackResolve) {
                window.helcimCallbackResolve(cardTokenInput.value);
                window.helcimCallbackResolve = null;
            }

            // Log field values
            console.error('=== Form Fields ===');
            console.error('Plan Type:', form.querySelector('input[name="plan_type"]')?.value);
            console.error('Signup Token:', form.querySelector('input[name="signup_token"]')?.value);
            console.error('Card Token:', cardTokenInput.value ? `${cardTokenInput.value.substring(0, 10)}...` : 'None');
        } catch (error) {
            console.error('Error in helcimCallback:', error);
            updateDebugInfo({
                type: 'callback_error',
                error: {
                    message: error.message,
                    stack: error.stack
                }
            });
            setErrorMessage(error.message || 'An error occurred during card verification');
            setIsProcessing(false);
            setLoadingMessage('');
        }
    };

    const processPayment = async () => {
        // Create a new Promise for this payment attempt
        const promise = new Promise((resolve) => {
            // Store the resolve function to be called by helcimCallback
            window.helcimCallbackResolve = resolve;
        });

        // Call helcimProcess
        helcimProcess();

        try {
            // Wait for the token
            const receivedToken = await promise;
            
            // Verify token matches what's in the form
            const form = document.getElementById('helcimForm');
            const formToken = form.querySelector('input[name="card_token"]')?.value;
            
            if (!formToken || formToken !== receivedToken) {
                throw new Error('Card token mismatch. Please try again.');
            }
            console.error('Token received, proceeding with submission');

            // Get helcimResults div
            const helcimResults = document.getElementById('helcimResults');
            if (!helcimResults) {
                throw new Error('Helcim results container not found');
            }

            // Create form data
            const formData = new FormData(form);
            
            // Add both card token versions to form data
            const cardTokenInput = helcimResults.querySelector('input[name="cardToken"]');
            if (!cardTokenInput || !cardTokenInput.value) {
                throw new Error('Card verification failed: No token received');
            }
            formData.set('card_token', cardTokenInput.value);
            formData.set('cardToken', cardTokenInput.value); // Also include original Helcim name
            
            // Log form data
            console.error('=== Form Data ===');
            const formEntries = {};
            formData.forEach((value, key) => {
                if (key === 'card_token' || key === 'cardToken') {
                    formEntries[key] = value ? `${value.substring(0, 10)}...` : 'None';
                    console.error(`${key}: ${formEntries[key]}`);
                } else {
                    formEntries[key] = value;
                    console.error(`${key}: ${value}`);
                }
            });

            updateDebugInfo({
                type: 'form_submission',
                formEntries
            });

            // Make the payment request
            console.error('Making fetch request to:', form.action);
            const response = await fetch(form.action, {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'application/json',
                    'Origin': window.location.origin
                }
            });

            // Get response text first
            const responseText = await response.text();
            console.error('Response status:', response.status);
            console.error('Response body:', responseText);

            updateDebugInfo({
                type: 'api_response',
                status: response.status,
                responseText
            });

            if (!response.ok) {
                let errorData;
                try {
                    errorData = JSON.parse(responseText);
                } catch (e) {
                    errorData = { detail: responseText };
                }
                console.error('Error response:', errorData);
                
                // Handle validation errors
                if (errorData.detail && Array.isArray(errorData.detail)) {
                    const errors = errorData.detail.map(err => {
                        if (err.type === 'missing' && err.loc.includes('card_token')) {
                            return 'Card verification failed. Please try again.';
                        }
                        return err.msg;
                    });
                    throw new Error(errors.join('\n'));
                }
                
                throw new Error(errorData.detail || 'Subscription setup failed');
            }

            const data = await JSON.parse(responseText);
            
            // Log parsed response
            console.error('=== Parsed Response ===');
            console.error('Status:', response.status);
            console.error('Data:', data);

            updateDebugInfo({
                type: 'api_success',
                data
            });

            // Show success message
            if (data.status === 'success') {
                if (data.access_token) {
                    localStorage.setItem('token', data.access_token);
                    console.error('Access token stored successfully');
                }
                setErrorMessage('');
                setLoadingMessage('Card verified successfully.');
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            console.error('Error getting token:', error);
            updateDebugInfo({
                type: 'payment_error',
                error: {
                    message: error.message,
                    stack: error.stack
                }
            });
            throw error;
        }
    };

    return (
        <div className="subscription-section">
            <div className="subscription-card">
                <div className="logo-container">
                    <img src="/images/3.png" alt="SalesOptAI" className="logo" />
                </div>
                <h2>Choose Your Plan</h2>
                <p>Start your 7-day free trial today. Cancel anytime.</p>

                <div className="plan-options">
                    <div 
                        className={`plan-card ${selectedPlan === 'monthly' ? 'selected' : ''}`}
                        onClick={() => handlePlanSelect('monthly')}
                    >
                        <h3>Monthly Plan</h3>
                        <p className="price">$97/month</p>
                        <ul>
                            <li>Full access to all features</li>
                            <li>Cancel anytime</li>
                            <li>7-day free trial</li>
                        </ul>
                    </div>

                    <div 
                        className={`plan-card ${selectedPlan === 'annual' ? 'selected' : ''}`}
                        onClick={() => handlePlanSelect('annual')}
                    >
                        <h3>Annual Plan</h3>
                        <p className="price">$970/year</p>
                        <p className="monthly-equivalent">Only $81/month</p>
                        <p className="savings">Save 16%</p>
                        <ul>
                            <li>All monthly features</li>
                            <li>2 months free</li>
                            <li>7-day free trial</li>
                        </ul>
                    </div>
                </div>

                {(errorMessage || (isProcessing && loadingMessage)) && (
                    <div className={`status-message ${errorMessage ? 'error' : 'loading'}`} style={{
                        background: errorMessage ? 'rgba(255, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)',
                        border: `1px solid ${errorMessage ? 'rgba(255, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)'}`,
                        padding: '16px 20px',
                        borderRadius: '12px',
                        marginBottom: '1.5rem',
                        color: errorMessage ? '#ff6b6b' : 'white',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                        fontSize: '1.1rem',
                        transition: 'all 0.3s ease'
                    }}>
                        {!errorMessage && (
                            <div className="loading-spinner" style={{
                                width: '24px',
                                height: '24px',
                                border: '3px solid rgba(255, 255, 255, 0.3)',
                                borderTop: '3px solid white',
                                borderRadius: '50%',
                                animation: 'spin 1s linear infinite'
                            }} />
                        )}
                        <span>{errorMessage || loadingMessage}</span>
                    </div>
                )}

                <form 
                    name="helcimForm" 
                    id="helcimForm" 
                    action={`${process.env.REACT_APP_API_URL}/api/payment/helcim/subscribe`}
                    method="POST"
                    className="payment-form"
                    onSubmit={(e) => {
                        e.preventDefault(); // Prevent traditional form submission
                        return false;
                    }}
                >
                    <div id="helcimResults"></div>
                    <input type="hidden" id="token" value={process.env.REACT_APP_HELCIM_JS_TOKEN} />
                    <input type="hidden" name="plan_type" value={selectedPlan} />
                    <input type="hidden" name="signup_token" value={new URLSearchParams(window.location.search).get('token')} />
                    <input type="hidden" id="setAsDefaultPaymentMethod" name="setAsDefaultPaymentMethod" value="1" />
                    
                    <h3>Payment Information</h3>
                    <div className="form-group">
                        <label htmlFor="cardNumber">Card Number</label>
                        <input
                            type="text"
                            id="cardNumber"
                            placeholder="1234 5678 9012 3456"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="cardHolderName">Name on Card</label>
                        <input
                            type="text"
                            id="cardHolderName"
                            placeholder="Name on Card"
                            required
                        />
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="cardExpiryMonth">Expiration Month</label>
                            <input
                                type="text"
                                id="cardExpiryMonth"
                                placeholder="MM"
                                maxLength="2"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="cardExpiryYear">Expiration Year</label>
                            <input
                                type="text"
                                id="cardExpiryYear"
                                placeholder="YY"
                                maxLength="2"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="cardCVV">CVV</label>
                            <input
                                type="text"
                                id="cardCVV"
                                placeholder="123"
                                maxLength="4"
                                required
                            />
                        </div>
                    </div>

                    <button 
                        type="button"
                        id="buttonProcess"
                        className="submit-button"
                        onClick={async (e) => {
                            e.preventDefault(); // Extra prevention of form submission
                            try {
                                // Verify form is ready
                                const form = document.getElementById('helcimForm');
                                if (!form) {
                                    throw new Error('Payment form not found. Please refresh the page.');
                                }

                                // Verify required fields
                                const cardNumber = document.getElementById('cardNumber')?.value;
                                const cardHolderName = document.getElementById('cardHolderName')?.value;
                                const cardExpiryMonth = document.getElementById('cardExpiryMonth')?.value;
                                const cardExpiryYear = document.getElementById('cardExpiryYear')?.value;
                                const cardCVV = document.getElementById('cardCVV')?.value;

                                if (!cardNumber || !cardHolderName || !cardExpiryMonth || !cardExpiryYear || !cardCVV) {
                                    throw new Error('Please fill in all card details');
                                }

                                // Ensure helcimProcess is available
                                if (typeof helcimProcess !== 'function') {
                                    console.error('helcimProcess not found');
                                    throw new Error('Payment system not ready. Please refresh the page.');
                                }

                                setIsProcessing(true);
                                setErrorMessage('');
                                cycleLoadingMessages();

                                // Log before processing
                                console.error('=== Starting Card Verification ===');
                                console.error('helcimProcess available:', typeof helcimProcess === 'function');
                                
                                // Verify token input has correct value
                                const tokenInput = document.getElementById('token');
                                if (!tokenInput || !tokenInput.value) {
                                    const error = 'Helcim token not properly initialized';
                                    console.error('ERROR:', error);
                                    updateDebugInfo({
                                        type: 'token_missing',
                                        error: {
                                            message: error
                                        }
                                    });
                                    throw new Error('Payment system not properly initialized. Please refresh the page.');
                                }

                                // Log pre-process state
                                console.error('=== Pre-Process State ===');
                                const formState = {
                                    cardNumber: cardNumber ? 'present' : 'missing',
                                    cardHolderName: cardHolderName ? 'present' : 'missing',
                                    cardExpiryMonth: cardExpiryMonth ? 'present' : 'missing',
                                    cardExpiryYear: cardExpiryYear ? 'present' : 'missing',
                                    cardCVV: cardCVV ? 'present' : 'missing'
                                };
                                console.error('Form Fields:', formState);
                                console.error('Helcim Token:', tokenInput.value);

                                updateDebugInfo({
                                    type: 'pre_process',
                                    formState
                                });

                                // Process payment and wait for token
                                await processPayment();

                            } catch (error) {
                                console.error('Error in payment processing:', error);
                                updateDebugInfo({
                                    type: 'process_error',
                                    error: {
                                        message: error.message,
                                        stack: error.stack
                                    }
                                });
                                setErrorMessage(error.message || 'Error processing payment. Please try again.');
                                setIsProcessing(false);
                                setLoadingMessage('');
                            }
                        }}
                        disabled={isProcessing}
                    >
                        {isProcessing ? 'Processing...' : 'Start Free Trial'}
                    </button>

                    <p className="terms">
                        By starting your free trial, you agree to our Terms of Service. 
                        Your card will not be charged during the trial period. 
                        You can cancel anytime before the trial ends.
                    </p>
                </form>
            </div>
        </div>
    );
};

export default SubscriptionSection;
