import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Checkbox, 
  FormControlLabel, 
  Button, 
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Chip
} from '@mui/material';
import { Delete as DeleteIcon, Info as InfoIcon } from '@mui/icons-material';
import { API_BASE_URL } from '../../../config/api';

const ToolsSettings = ({ assistant, onUpdate }) => {
  const [loading, setLoading] = useState(true);
  const [availableTools, setAvailableTools] = useState([]);
  const [attachedTools, setAttachedTools] = useState([]);
  const [selectedTools, setSelectedTools] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // Fetch all available tools
  useEffect(() => {
    const fetchTools = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${API_BASE_URL}/api/tools`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          credentials: 'include',
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch tools');
        }
        
        const data = await response.json();
        setAvailableTools(data.tools || []);
      } catch (err) {
        setError('Failed to load available tools. Please try again later.');
        console.error(err);
      }
    };

    fetchTools();
  }, []);

  // Fetch tools attached to this assistant
  useEffect(() => {
    const fetchAttachedTools = async () => {
      if (!assistant || !assistant.id) return;
      
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${API_BASE_URL}/api/logical-assistants/${assistant.id}/tools`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          credentials: 'include',
        });
        
        // Handle 404 (Not Found) as a normal case - it just means no tools are attached
        if (response.status === 404) {
          setAttachedTools([]);
          return;
        }
        
        // For other non-OK responses, treat as an error
        if (!response.ok) {
          throw new Error(`Failed to fetch attached tools: ${response.status}`);
        }
        
        const data = await response.json();
        setAttachedTools(data.tools || []);
      } catch (err) {
        // Only set error for real errors, not for "no tools" case
        console.error('Error fetching attached tools:', err);
        setError('Failed to load attached tools. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchAttachedTools();
  }, [assistant]);

  // Handle tool selection
  const handleToolSelection = (toolId) => {
    setSelectedTools(prev => {
      if (prev.includes(toolId)) {
        return prev.filter(id => id !== toolId);
      } else {
        return [...prev, toolId];
      }
    });
  };

  // Attach selected tools to the assistant
  const handleAttachTools = async () => {
    if (selectedTools.length === 0) return;
    
    try {
      setLoading(true);
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_BASE_URL}/api/logical-assistants/${assistant.id}/tools`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        body: JSON.stringify({ tool_ids: selectedTools }),
      });
      
      if (!response.ok) {
        throw new Error('Failed to attach tools');
      }
      
      // Refresh the list of attached tools
      const attachedResponse = await fetch(`${API_BASE_URL}/api/logical-assistants/${assistant.id}/tools`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
      });
      
      if (!attachedResponse.ok) {
        throw new Error('Failed to refresh attached tools');
      }
      
      const data = await attachedResponse.json();
      setAttachedTools(data.tools || []);
      setSelectedTools([]);
      setSuccess('Tools attached successfully');
      
      // Clear success message after 3 seconds
      setTimeout(() => {
        setSuccess(null);
      }, 3000);
      
      // Call the onUpdate callback to refresh the parent component
      if (onUpdate) onUpdate();
    } catch (err) {
      setError('Failed to attach tools. Please try again later.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Detach a tool from the assistant
  const handleDetachTool = async (toolId) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_BASE_URL}/api/logical-assistants/${assistant.id}/tools/${toolId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
      });
      
      if (!response.ok) {
        throw new Error('Failed to detach tool');
      }
      
      // Update the local state
      setAttachedTools(prev => prev.filter(tool => tool.id !== toolId));
      setSuccess('Tool detached successfully');
      
      // Clear success message after 3 seconds
      setTimeout(() => {
        setSuccess(null);
      }, 3000);
      
      // Call the onUpdate callback to refresh the parent component
      if (onUpdate) onUpdate();
    } catch (err) {
      setError('Failed to detach tool. Please try again later.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Filter out already attached tools from available tools
  const unattachedTools = availableTools.filter(
    tool => !attachedTools.some(attached => attached.id === tool.id)
  );

  if (loading && (!availableTools.length || !attachedTools)) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}
      
      {/* Currently attached tools */}
      <Paper sx={{ p: 3, bgcolor: 'background.paper' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Attached Tools
        </Typography>
        
        {attachedTools.length === 0 ? (
          <Typography color="text.secondary">
            No tools are currently attached to this assistant.
          </Typography>
        ) : (
          <List>
            {attachedTools.map(tool => (
              <ListItem key={tool.id} divider>
                <ListItemText 
                  primary={tool.name}
                  secondary={
                    <>
                      {tool.description}
                      <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
                        <Chip 
                          size="small" 
                          label={tool.is_custom ? "Custom" : "Built-in"} 
                          color={tool.is_custom ? "secondary" : "primary"} 
                          variant="outlined"
                        />
                        <Chip 
                          size="small" 
                          label={`Function: ${tool.function_name}`} 
                          variant="outlined" 
                        />
                      </Box>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <Tooltip title="Detach tool">
                    <IconButton 
                      edge="end" 
                      aria-label="detach" 
                      onClick={() => handleDetachTool(tool.id)}
                      disabled={loading}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
      
      {/* Available tools to attach */}
      <Paper sx={{ p: 3, bgcolor: 'background.paper' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Available Tools
        </Typography>
        
        {unattachedTools.length === 0 ? (
          <Typography color="text.secondary">
            All available tools are already attached to this assistant.
          </Typography>
        ) : (
          <>
            <List>
              {unattachedTools.map(tool => (
                <ListItem key={tool.id} divider>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedTools.includes(tool.id)}
                        onChange={() => handleToolSelection(tool.id)}
                        disabled={loading}
                      />
                    }
                    label={
                      <Box>
                        <Typography variant="body1">{tool.name}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {tool.description}
                        </Typography>
                        <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
                          <Chip 
                            size="small" 
                            label={tool.is_custom ? "Custom" : "Built-in"} 
                            color={tool.is_custom ? "secondary" : "primary"} 
                            variant="outlined"
                          />
                          <Chip 
                            size="small" 
                            label={`Function: ${tool.function_name}`} 
                            variant="outlined" 
                          />
                        </Box>
                      </Box>
                    }
                  />
                </ListItem>
              ))}
            </List>
            
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAttachTools}
                disabled={selectedTools.length === 0 || loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Attach Selected Tools'}
              </Button>
            </Box>
          </>
        )}
      </Paper>
      
      <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
        <InfoIcon color="info" fontSize="small" />
        <Typography variant="body2" color="text.secondary">
          Tools allow your assistant to perform actions or retrieve information. Attach tools to enhance your assistant's capabilities.
        </Typography>
      </Box>
    </Box>
  );
};

export default ToolsSettings;
