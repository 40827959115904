import { API_BASE_URL } from '../../config/api';

export const formatDate = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

export const fetchCreditBalance = async (userId, token) => {
  const response = await fetch(`${API_BASE_URL}/api/billing/credit-balance/${userId}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  if (!response.ok) {
    const errorText = await response.text();
    console.error('Server response:', response.status, errorText);
    throw new Error(`Server error: ${response.status}`);
  }
  return await response.json();
};

export const fetchUsageHistory = async (userId, token) => {
  const response = await fetch(`${API_BASE_URL}/api/billing/usage/${userId}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  if (!response.ok) {
    const errorText = await response.text();
    console.error('Server response:', response.status, errorText);
    throw new Error(`Server error: ${response.status}`);
  }
  return await response.json();
};

export const fetchSubscriptionDetails = async (userId, token) => {
  const response = await fetch(`${API_BASE_URL}/api/billing/subscription/${userId}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  if (!response.ok) {
    const errorText = await response.text();
    console.error('Server response:', response.status, errorText);
    throw new Error(`Server error: ${response.status}`);
  }
  return await response.json();
};

// Add-on related API functions
export const fetchAvailableAddOns = async (token) => {
  const response = await fetch(`${API_BASE_URL}/api/billing/addons`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  if (!response.ok) {
    const errorText = await response.text();
    console.error('Server response:', response.status, errorText);
    throw new Error(`Server error: ${response.status}`);
  }
  return await response.json();
};

export const fetchUserAddOns = async (userId, token) => {
  const response = await fetch(`${API_BASE_URL}/api/billing/addons/${userId}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  if (!response.ok) {
    const errorText = await response.text();
    console.error('Server response:', response.status, errorText);
    throw new Error(`Server error: ${response.status}`);
  }
  return await response.json();
};

export const subscribeToAddOn = async (userId, addonId, quantity, token) => {
  const response = await fetch(`${API_BASE_URL}/api/billing/addons/${userId}/subscribe`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      addon_id: addonId,
      quantity: quantity
    })
  });
  if (!response.ok) {
    const errorText = await response.text();
    console.error('Server response:', response.status, errorText);
    throw new Error(`Server error: ${response.status}`);
  }
  return await response.json();
};

export const unsubscribeFromAddOn = async (userId, addonId, token) => {
  const response = await fetch(`${API_BASE_URL}/api/billing/addons/${userId}/unsubscribe`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      addon_id: addonId
    })
  });
  if (!response.ok) {
    const errorText = await response.text();
    console.error('Server response:', response.status, errorText);
    throw new Error(`Server error: ${response.status}`);
  }
  return await response.json();
};
