import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { UserProvider, UserContext } from './UserContext';
import { SnackbarProvider } from 'notistack';
import { useContext } from 'react';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import Account from './pages/Account';
import Billing from './pages/Billing';
import Conversations from './pages/Conversations';
import Assistants from './pages/Assistants';
import Admin from './pages/Admin';
import Tools from './pages/Tools';
import LeadFlow from './pages/LeadFlow';
import ResetPassword from './pages/ResetPassword';
import SubscriptionSetup from './pages/SubscriptionSetup';
import CompleteSetup from './pages/CompleteSetup';
import ProtectedRoute from './components/ProtectedRoute';
import LeadFlowProtectedRoute from './components/LeadFlowProtectedRoute';
import './App.css';

// Root redirect component that uses UserContext instead of localStorage
const RootRedirect = () => {
  const { userId } = useContext(UserContext);
  return <Navigate to={userId ? "/dashboard" : "/login"} replace />;
};

function App() {
  return (
    <UserProvider>
      <SnackbarProvider maxSnack={3}>
        <Router>
        <Routes>
          {/* Public routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/subscription-setup" element={<SubscriptionSetup />} />
          <Route path="/subscription" element={<SubscriptionSetup />} /> {/* Alias for cleaner URLs */}
          <Route path="/complete-setup/:userId" element={<CompleteSetup />} />

          {/* Protected routes */}
          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/account" element={<Account />} />
            <Route path="/billing" element={<Billing />} />
            <Route path="/assistants" element={<Assistants />} />
            <Route path="/conversations" element={<Conversations />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/tools" element={<Tools />} />
          </Route>
          
          {/* LeadFlow protected route - requires LeadFlow add-on */}
          <Route element={<LeadFlowProtectedRoute />}>
            <Route path="/leadflow" element={<LeadFlow />} />
          </Route>

          {/* Redirect root to dashboard or login based on auth state */}
          <Route path="/" element={<RootRedirect />} />
        </Routes>
        </Router>
      </SnackbarProvider>
    </UserProvider>
  );
}

export default App;
