import React, { createContext, useState, useEffect, useCallback } from 'react';
import { fetchUserAddOns } from './pages/billing/BillingUtils';

// Function to check if a JWT token is expired
const checkIfTokenIsExpired = (token) => {
  try {
    // Split the token and get the payload part
    const payload = token.split('.')[1];
    // Decode the base64 payload
    const decodedPayload = JSON.parse(atob(payload));
    // Check if the token is expired
    return decodedPayload.exp * 1000 < Date.now();
  } catch (error) {
    console.error('Error decoding token:', error);
    // If we can't decode the token, assume it's invalid
    return true;
  }
};

// Create a Context for the user
export const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(() => {
    // Retrieve userId from local storage if available
    const storedUserId = localStorage.getItem('userId');
    return storedUserId ? parseInt(storedUserId, 10) : null;
  });

  const [userEmail, setUserEmail] = useState(() => {
    // Retrieve userEmail from local storage if available
    return localStorage.getItem('userEmail') || null;
  });

  const [isAdmin, setIsAdmin] = useState(() => {
    // Retrieve isAdmin from local storage if available
    const storedIsAdmin = localStorage.getItem('isAdmin');
    return storedIsAdmin === 'true';
  });

  const [userAddOns, setUserAddOns] = useState([]);
  const [hasLeadFlow, setHasLeadFlow] = useState(false);

  // Load user add-ons
  const loadUserAddOns = useCallback(async () => {
    if (!userId) return;
    
    try {
      const token = localStorage.getItem('access_token');
      if (!token) return;
      
      const addons = await fetchUserAddOns(userId, token);
      setUserAddOns(addons || []);
      
      // Check if user has LeadFlow add-on
      const hasLeadFlowAddon = addons?.some(addon => 
        addon.addon?.name === 'LeadFlow' && addon.status === 'active'
      );
      setHasLeadFlow(hasLeadFlowAddon);
    } catch (error) {
      console.error('Error loading user add-ons:', error);
      setUserAddOns([]);
      setHasLeadFlow(false);
    }
  }, [userId]);

  // Load user add-ons when userId changes
  useEffect(() => {
    loadUserAddOns();
  }, [loadUserAddOns]);

  // Example: Set the user ID, email, and admin status after login
  const login = (id, email = null, adminStatus = false) => {
    const parsedId = parseInt(id, 10);
    setUserId(parsedId);
    setUserEmail(email);
    setIsAdmin(adminStatus);
    localStorage.setItem('userId', parsedId.toString());
    if (email) localStorage.setItem('userEmail', email);
    localStorage.setItem('isAdmin', adminStatus.toString());
  };

  const logout = () => {
    setUserId(null);
    setUserEmail(null);
    setIsAdmin(false);
    localStorage.removeItem('userId');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('access_token');
  };

  // Validate token on initialization - only run once at startup
  useEffect(() => {
    const validateToken = () => {
      try {
        const token = localStorage.getItem('access_token');
        if (token) {
          const isExpired = checkIfTokenIsExpired(token);
          if (isExpired) {
            console.log('Token has expired, logging out');
            // Clear localStorage directly instead of using logout function
            localStorage.removeItem('userId');
            localStorage.removeItem('userEmail');
            localStorage.removeItem('isAdmin');
            localStorage.removeItem('access_token');
            // Update state
            setUserId(null);
            setUserEmail(null);
            setIsAdmin(false);
          } else {
            console.log('Token is valid');
          }
        }
      } catch (error) {
        console.error('Error validating token:', error);
      }
    };
    
    validateToken();
    // No dependencies - run only once at initialization
  }, []);

  // Update local storage whenever userId, userEmail, or isAdmin changes
  useEffect(() => {
    if (userId) {
      localStorage.setItem('userId', userId.toString());
      if (userEmail) localStorage.setItem('userEmail', userEmail);
      localStorage.setItem('isAdmin', isAdmin.toString());
    } else {
      localStorage.removeItem('userId');
      localStorage.removeItem('userEmail');
      localStorage.removeItem('isAdmin');
      localStorage.removeItem('access_token');
    }
  }, [userId, userEmail, isAdmin]);

  return (
    <UserContext.Provider value={{ 
      userId, 
      userEmail, 
      isAdmin, 
      userAddOns,
      hasLeadFlow,
      setUserId, 
      setUserEmail, 
      login, 
      logout,
      loadUserAddOns
    }}>
      {children}
    </UserContext.Provider>
  );
};
