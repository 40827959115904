import { User } from 'react-feather';
import { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../config/api';
import { formatPhoneNumber } from '../../utils/formatters';

const ProfileSection = ({ userId }) => {
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [profile, setProfile] = useState({
    businessName: '',
    contactName: '',
    contactPhone: '+1',
    businessAddress: '',
    website: '',
    email: ''
  });

  useEffect(() => {
    const fetchProfile = async () => {
      if (!userId) {
        setLoading(false);
        return;
      }
      
      try {
        console.log('\n=== Account Profile API Call ===');
        const token = localStorage.getItem('access_token');
        const url = `${API_BASE_URL}/api/user-profile`;
        const headers = {
          'Authorization': `Bearer ${token}`
        };
        
        console.log('Request URL:', url);
        console.log('API_BASE_URL:', API_BASE_URL);
        console.log('Headers:', headers);
        
        const response = await fetch(url, { headers });
        console.log('Response Status:', response.status);
        console.log('Response Headers:', Object.fromEntries(response.headers.entries()));
        if (response.ok) {
          const data = await response.json();
          setProfile({
            businessName: data.business_name || '',
            contactName: data.contact_name || '',
            contactPhone: data.contact_phone || '+1',
            businessAddress: data.business_address || '',
            website: data.business_url || '',
            email: data.email || ''
          });
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === 'contactPhone') {
      newValue = formatPhoneNumber(value);
    }

    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: newValue
    }));
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    if (!userId) return;

    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_BASE_URL}/api/user-profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          business_name: profile.businessName,
          contact_name: profile.contactName,
          contact_phone: profile.contactPhone,
          business_address: profile.businessAddress,
          business_url: profile.website,
          email: profile.email
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setSuccessMessage(data.message);
        // Clear success message after 3 seconds
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  if (loading) {
    return (
      <section className="account-section">
        <div style={{ textAlign: 'center', padding: '2rem' }}>
          Loading profile information...
        </div>
      </section>
    );
  }

  return (
    <section className="account-section">
      <h2 className="section-header">
        <User style={{ color: 'white', width: '1.2rem', height: '1.2rem' }} />
        Profile Information
      </h2>
      {successMessage && (
        <div className="success-message" style={{ 
          backgroundColor: '#d4edda', 
          color: '#155724', 
          padding: '1rem', 
          borderRadius: '4px', 
          marginBottom: '1rem' 
        }}>
          {successMessage}
        </div>
      )}
      <form onSubmit={handleSaveChanges}>
        <div className="grid-container">
          <div className="input-group">
            <label>Business Name</label>
            <input
              type="text"
              name="businessName"
              value={profile.businessName}
              onChange={handleInputChange}
              placeholder="Business Name"
              required
            />
          </div>
          <div className="input-group">
            <label>Contact Name</label>
            <input
              type="text"
              name="contactName"
              value={profile.contactName}
              onChange={handleInputChange}
              placeholder="Contact Name"
              required
            />
          </div>
          <div className="input-group">
            <label>Contact Phone</label>
            <input
              type="tel"
              name="contactPhone"
              value={profile.contactPhone}
              onChange={handleInputChange}
              placeholder="+1 (555) 555-5555"
              required
            />
          </div>
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={profile.email}
              onChange={handleInputChange}
              placeholder="Email"
              required
            />
          </div>
          <div className="input-group">
            <label>Website</label>
            <input
              type="text"
              name="website"
              value={profile.website}
              onChange={handleInputChange}
              placeholder="Website"
              required
            />
          </div>
        </div>
        <div className="input-group">
          <label>Business Address</label>
          <input
            type="text"
            name="businessAddress"
            value={profile.businessAddress}
            onChange={handleInputChange}
            placeholder="Business Address"
            required
          />
        </div>
        <button type="submit" className="primary-button">Save Profile Changes</button>
      </form>
    </section>
  );
};

export default ProfileSection;
