import { useState, useContext, useEffect } from 'react';
import { UserContext } from '../UserContext';
import PageContainer from '../components/PageContainer';
import ProfileSection from '../components/account/ProfileSection';
import PasswordChangeSection from '../components/account/PasswordChangeSection';
import '../styles/account.css';

const Account = () => {
  const { userId } = useContext(UserContext);
  const [pageLoading, setPageLoading] = useState(true);

  // Set loading to false after initial mount
  useEffect(() => {
    setPageLoading(false);
  }, []);

  const handleLogout = () => {
    console.log('Logout clicked');
  };

  return (
    <PageContainer
      loading={pageLoading}
      loadingMessage="Loading account information..."
      title="Account Settings"
      subtitle="Manage your profile and security settings"
      onLogout={handleLogout}
    >
      <div style={{ display: 'grid', gap: '1.5rem' }}>
        <ProfileSection userId={userId} />
        <PasswordChangeSection />
      </div>
    </PageContainer>
  );
};

export default Account;
