import React from 'react';
import { Tool } from 'react-feather';
import ToolsList from './ToolsList';

const ToolsSection = ({ tools, onEdit, onDelete }) => {
  return (
    <section className="tools-section">
      <h2 className="section-header">
        <Tool style={{ color: 'white', width: '1.2rem', height: '1.2rem' }} />
        Available Tools
      </h2>
      <p className="section-description">
        These tools can be used by your assistants to perform actions or retrieve information.
      </p>
      
      <ToolsList 
        tools={tools} 
        onEdit={onEdit} 
        onDelete={onDelete} 
      />
    </section>
  );
};

export default ToolsSection;
