import React from 'react';
import { Grid } from '@mui/material';
import AssistantCard from './AssistantCard';

const AssistantList = ({ assistants, loading, onUpdate }) => {
  if (loading) {
    return <div>Loading assistants...</div>;
  }

  return (
    <Grid container spacing={3} sx={{ width: '100%', margin: 0, padding: 2 }}>
      {assistants.map((assistant) => (
        <Grid item xs={12} key={assistant.id} sx={{ display: 'flex' }}>
          <AssistantCard 
            assistant={assistant}
            onUpdate={onUpdate}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default AssistantList;
