import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../UserContext';

const LeadFlowProtectedRoute = () => {
  const { userId, hasLeadFlow } = useContext(UserContext);

  // If user is not logged in, redirect to login
  if (!userId) {
    return <Navigate to="/login" replace />;
  }

  // If user doesn't have LeadFlow add-on, redirect to dashboard
  if (!hasLeadFlow) {
    return <Navigate to="/dashboard" replace />;
  }

  // If user is logged in and has LeadFlow add-on, render the child routes
  return <Outlet />;
};

export default LeadFlowProtectedRoute;
