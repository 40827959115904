import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Button, 
  Alert, 
  CircularProgress, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemIcon, 
  ListItemSecondaryAction, 
  IconButton,
  Divider,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import { API_BASE_URL } from '../../../config/api';

// Helper function to format file size
const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

// File upload component
const FileUpload = ({ onUpload, disabled }) => {
  const [dragActive, setDragActive] = useState(false);
  
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0] && !disabled) {
      onUpload(e.dataTransfer.files);
    }
  };
  
  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      onUpload(e.target.files);
    }
  };
  
  return (
    <Box 
      sx={{ 
        border: '2px dashed', 
        borderColor: dragActive ? 'primary.main' : 'grey.300',
        borderRadius: 1,
        p: 3,
        textAlign: 'center',
        bgcolor: dragActive ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
        transition: 'all 0.2s ease'
      }}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <input
        type="file"
        multiple
        onChange={handleChange}
        style={{ display: 'none' }}
        id="file-upload"
        accept=".pdf,.txt,.doc,.docx,.md"
        disabled={disabled}
      />
      <label htmlFor="file-upload">
        <Button
          component="span"
          variant="contained"
          disabled={disabled}
          startIcon={<UploadFileIcon />}
        >
          Select Files
        </Button>
      </label>
      <Typography variant="body2" sx={{ mt: 1 }}>
        or drag and drop files here
      </Typography>
      <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
        Supported formats: PDF, TXT, DOC, DOCX, MD
      </Typography>
      <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
        Maximum file size: 300KB (recommended)
      </Typography>
    </Box>
  );
};

// File list component
const FileList = ({ files, onDelete, loading }) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  
  const handleDeleteClick = (file) => {
    setFileToDelete(file);
    setDeleteDialogOpen(true);
  };
  
  const handleConfirmDelete = () => {
    if (fileToDelete) {
      onDelete(fileToDelete.id);
    }
    setDeleteDialogOpen(false);
    setFileToDelete(null);
  };
  
  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setFileToDelete(null);
  };
  
  // Get status icon based on file status
  const getStatusIcon = (status) => {
    switch (status) {
      case 'processing':
        return <CircularProgress size={16} />;
      case 'failed':
        return <ErrorIcon color="error" />;
      default:
        return null;
    }
  };
  
  return (
    <>
      <List>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
            <CircularProgress />
          </Box>
        ) : files.length === 0 ? (
          <Typography color="text.secondary" sx={{ p: 2, textAlign: 'center' }}>
            No files uploaded yet
          </Typography>
        ) : (
          files.map((file) => (
            <ListItem key={file.id}>
              <ListItemIcon>
                <InsertDriveFileIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {file.file_name}
                    {getStatusIcon(file.status)}
                    {file.status === 'failed' && (
                      <Tooltip title={file.error_message || 'Upload failed'}>
                        <InfoIcon color="error" fontSize="small" />
                      </Tooltip>
                    )}
                  </Box>
                }
                secondary={
                  <>
                    {formatFileSize(file.file_size)} • {file.file_type.toUpperCase()}
                  </>
                }
              />
              <ListItemSecondaryAction>
                <IconButton 
                  edge="end" 
                  onClick={() => handleDeleteClick(file)}
                  disabled={file.status === 'processing'}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))
        )}
      </List>
      
      {/* Delete confirmation dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCancelDelete}
      >
        <DialogTitle>Delete File</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete "{fileToDelete?.file_name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const KnowledgeSettings = ({ assistant, onUpdate }) => {
  // We still need to track if a knowledge base exists, but we don't expose this to the user
  // eslint-disable-next-line no-unused-vars
  const [hasKnowledgeBase, setHasKnowledgeBase] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // Function to fetch knowledge base info - using useCallback to avoid dependency issues
  const fetchKnowledgeBase = useCallback(async () => {
    if (!assistant || !assistant.id) return;
    
    setLoading(true);
    setError('');
    
    try {
      const response = await fetch(`${API_BASE_URL}/api/knowledge/${assistant.id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || `Server error: ${response.status}`);
      }
      
      const data = await response.json();
      setHasKnowledgeBase(data.has_knowledge_base);
      setFiles(data.files || []);
    } catch (error) {
      console.error('Error fetching knowledge base:', error);
      setError('Failed to load knowledge base information');
    } finally {
      setLoading(false);
    }
  }, [assistant]);
  
  // Load knowledge base info on mount
  useEffect(() => {
    fetchKnowledgeBase();
  }, [fetchKnowledgeBase]);
  
  // Upload file
  const uploadFile = async (files) => {
    setUploading(true);
    setError('');
    
    try {
      // Upload each file - the backend will create a knowledge base if needed
      for (const file of files) {
        const formData = new FormData();
        formData.append('file', file);
        
        const response = await fetch(`${API_BASE_URL}/api/knowledge/${assistant.id}/files`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          },
          body: formData
        });
        
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(errorText || `Server error: ${response.status}`);
        }
        
        const data = await response.json();
        setFiles(prevFiles => [...prevFiles, data]);
      }
      
      // Refresh knowledge base info after upload
      await fetchKnowledgeBase();
      
      setSuccess('File(s) uploaded successfully');
      
      // Clear success message after a delay
      setTimeout(() => {
        setSuccess('');
      }, 3000);
      
      // Notify parent component
      if (onUpdate) {
        onUpdate();
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setError('Failed to upload file: ' + error.message);
    } finally {
      setUploading(false);
    }
  };
  
  // Delete file
  const deleteFile = async (fileId) => {
    setError('');
    
    try {
      const response = await fetch(`${API_BASE_URL}/api/knowledge/${assistant.id}/files/${fileId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || `Server error: ${response.status}`);
      }
      
      // Remove file from state
      setFiles(prevFiles => prevFiles.filter(file => file.id !== fileId));
      
      setSuccess('File deleted successfully');
      
      // Clear success message after a delay
      setTimeout(() => {
        setSuccess('');
      }, 3000);
      
      // Notify parent component
      if (onUpdate) {
        onUpdate();
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      setError('Failed to delete file');
    }
  };
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}
      
      {/* File Upload Section */}
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6">Upload Files</Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 2 }}>
          Upload files to add them to the assistant's knowledge. The assistant will use this information to provide more accurate responses.
        </Typography>
        
        <FileUpload
          onUpload={uploadFile}
          disabled={uploading}
        />
        
        {uploading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <CircularProgress size={24} />
            <Typography variant="body2" sx={{ ml: 1 }}>
              Uploading...
            </Typography>
          </Box>
        )}
      </Paper>
      
      {/* File List Section */}
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6">Files</Typography>
        <Divider sx={{ my: 2 }} />
        
        <FileList
          files={files}
          onDelete={deleteFile}
          loading={loading}
        />
      </Paper>
    </Box>
  );
};

export default KnowledgeSettings;
