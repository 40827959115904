import { Link, useLocation } from 'react-router-dom';
import { Home, User, CreditCard, MessageCircle, LogOut, Menu, Settings, Zap, Tool, Users } from 'react-feather';
import { useContext, useState } from 'react';
import { UserContext } from '../UserContext';

const MobileMenu = () => {
  const location = useLocation();
  const { logout, isAdmin, hasLeadFlow } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mobile-menu">
      <div className="mobile-menu-header">
        <img src="/images/3.png" className="mobile-logo" alt="SalesOptAI" />
        <button className="mobile-menu-toggle" onClick={toggleMenu}>
          <Menu />
        </button>
      </div>
      {isOpen && (
        <nav className="mobile-nav">
          <Link 
            className={`mobile-nav-link ${location.pathname === '/dashboard' ? 'active' : ''}`} 
            to="/dashboard"
            onClick={() => setIsOpen(false)}
          >
            <Home /> Dashboard
          </Link>
          <Link 
            className={`mobile-nav-link ${location.pathname === '/account' ? 'active' : ''}`} 
            to="/account"
            onClick={() => setIsOpen(false)}
          >
            <User /> Account
          </Link>
          <Link 
            className={`mobile-nav-link ${location.pathname === '/billing' ? 'active' : ''}`} 
            to="/billing"
            onClick={() => setIsOpen(false)}
          >
            <CreditCard /> Billing
          </Link>
          <Link 
            className={`mobile-nav-link ${location.pathname === '/assistants' ? 'active' : ''}`} 
            to="/assistants"
            onClick={() => setIsOpen(false)}
          >
            <Users /> Assistants
          </Link>
          <Link 
            className={`mobile-nav-link ${location.pathname === '/conversations' ? 'active' : ''}`} 
            to="/conversations"
            onClick={() => setIsOpen(false)}
          >
            <MessageCircle /> Conversations
          </Link>
          {hasLeadFlow && (
            <Link 
              className={`mobile-nav-link ${location.pathname === '/leadflow' ? 'active' : ''}`} 
              to="/leadflow"
              onClick={() => setIsOpen(false)}
            >
              <Zap /> LeadFlow
            </Link>
          )}
          {isAdmin && (
            <>
              <Link 
                className={`mobile-nav-link ${location.pathname === '/tools' ? 'active' : ''}`} 
                to="/tools"
                onClick={() => setIsOpen(false)}
              >
                <Tool /> Tools
              </Link>
              <Link 
                className={`mobile-nav-link ${location.pathname === '/admin' ? 'active' : ''}`} 
                to="/admin"
                onClick={() => setIsOpen(false)}
              >
                <Settings /> Admin
              </Link>
            </>
          )}
          <button 
            className="mobile-nav-link logout-button" 
            onClick={() => {
              logout();
              setIsOpen(false);
            }}
          >
            <LogOut /> Log Out
          </button>
        </nav>
      )}
    </div>
  );
};

export default MobileMenu;
