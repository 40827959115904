import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../UserContext';
import { Plus } from 'react-feather';
import { API_BASE_URL } from '../config/api';
import PageContainer from '../components/PageContainer';
import ToolsSection from '../components/tools/ToolsSection';
import ToolForm from '../components/tools/ToolForm';
import '../styles/tools.css';

const Tools = () => {
  const { isAdmin } = useContext(UserContext);
  const [tools, setTools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editingTool, setEditingTool] = useState(null);

  useEffect(() => {
    fetchTools();
  }, []);

  const fetchTools = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_BASE_URL}/api/tools`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch tools');
      }
      
      const data = await response.json();
      setTools(data.tools);
      setError(null);
    } catch (err) {
      setError('Failed to load tools. Please try again later.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddTool = () => {
    setEditingTool(null);
    setShowAddForm(true);
    setSuccessMessage(null);
    setError(null);
  };

  const handleEditTool = (tool) => {
    setEditingTool(tool);
    setShowAddForm(true);
    setSuccessMessage(null);
    setError(null);
  };

  const handleDeleteTool = async (id) => {
    if (window.confirm('Are you sure you want to delete this tool?')) {
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${API_BASE_URL}/api/tools/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          credentials: 'include',
        });
        
        if (!response.ok) {
          throw new Error('Failed to delete tool');
        }
        
        setSuccessMessage('Tool deleted successfully');
        // Clear success message after 3 seconds
        setTimeout(() => {
          setSuccessMessage(null);
        }, 3000);
        
        fetchTools();
      } catch (err) {
        setError('Failed to delete tool. Please try again later.');
        console.error(err);
      }
    }
  };

  const handleFormSubmit = async (toolData) => {
    try {
      let response;
      const token = localStorage.getItem('access_token');
      
      if (editingTool) {
        response = await fetch(`${API_BASE_URL}/api/tools/${editingTool.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          credentials: 'include',
          body: JSON.stringify(toolData),
        });
      } else {
        response = await fetch(`${API_BASE_URL}/api/tools`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          credentials: 'include',
          body: JSON.stringify(toolData),
        });
      }
      
      if (!response.ok) {
        throw new Error('Failed to save tool');
      }
      
      setSuccessMessage(editingTool ? 'Tool updated successfully' : 'Tool added successfully');
      
      // Clear success message after 3 seconds
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
      
      setShowAddForm(false);
      setEditingTool(null);
      fetchTools();
    } catch (err) {
      setError('Failed to save tool. Please check your input and try again.');
      console.error(err);
    }
  };

  const handleCancelForm = () => {
    setShowAddForm(false);
    setEditingTool(null);
    setError(null);
  };

  return (
    <PageContainer
      loading={loading}
      loadingMessage="Loading tools..."
      title="Tools"
      subtitle="Manage your assistant tools"
    >
      {/* Show success message at the top level if not in form */}
      {!showAddForm && successMessage && (
        <div className="save-message success" style={{ marginBottom: '1.5rem' }}>
          {successMessage}
        </div>
      )}
      
      {/* Show error message at the top level if not in form */}
      {!showAddForm && error && (
        <div className="save-message error" style={{ marginBottom: '1.5rem' }}>
          {error}
        </div>
      )}
      
      {showAddForm ? (
        <ToolForm 
          initialData={editingTool}
          onSubmit={handleFormSubmit}
          onCancel={handleCancelForm}
          successMessage={showAddForm ? successMessage : null}
          errorMessage={showAddForm ? error : null}
          isAdmin={isAdmin}
        />
      ) : (
        <>
          <ToolsSection 
            tools={tools} 
            onEdit={handleEditTool} 
            onDelete={handleDeleteTool} 
          />
          
          <button 
            className="add-tool-button"
            onClick={handleAddTool}
            aria-label="Add new tool"
          >
            <Plus size={24} />
          </button>
        </>
      )}
    </PageContainer>
  );
};

export default Tools;
