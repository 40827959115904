import React, { useState, useEffect, useRef } from 'react';
import { Plus, Trash2, ChevronDown, ChevronUp } from 'react-feather';

const ParameterEditor = ({ parameters, onChange }) => {
  const [properties, setProperties] = useState({});
  const [requiredProps, setRequiredProps] = useState([]);
  const [expandedProps, setExpandedProps] = useState({});
  const [initialLoad, setInitialLoad] = useState(true);
  const nameInputRef = useRef(null);

  // Parse parameters when component mounts or parameters change
  useEffect(() => {
    try {
      let parsedParams;
      if (typeof parameters === 'string') {
        parsedParams = JSON.parse(parameters);
      } else {
        parsedParams = parameters;
      }

      // Extract properties and required fields
      if (parsedParams && parsedParams.properties) {
        setProperties(parsedParams.properties);
        setRequiredProps(parsedParams.required || []);
      } else {
        // Initialize with empty structure if no properties
        setProperties({});
        setRequiredProps([]);
      }
      
      // Mark initial load as complete
      if (initialLoad) {
        setInitialLoad(false);
      }
    } catch (error) {
      console.error('Error parsing parameters:', error);
      setProperties({});
      setRequiredProps([]);
      
      // Mark initial load as complete even on error
      if (initialLoad) {
        setInitialLoad(false);
      }
    }
  }, [parameters, initialLoad]);

  // We're handling parent notifications directly in each handler function

  // Toggle property expansion
  const toggleProperty = (propName) => {
    setExpandedProps(prev => ({
      ...prev,
      [propName]: !prev[propName]
    }));
  };

  // Add a new property
  const addProperty = () => {
    const newPropName = `property_${Object.keys(properties).length + 1}`;
    const newProperties = {
      ...properties,
      [newPropName]: {
        type: 'string',
        description: ''
      }
    };
    
    setProperties(newProperties);
    setExpandedProps(prev => ({
      ...prev,
      [newPropName]: true
    }));
    
    // Notify parent after state update
    setTimeout(() => {
      onChange({
        type: 'object',
        properties: newProperties,
        required: requiredProps
      });
    }, 0);
  };

  // Remove a property
  const removeProperty = (propName) => {
    const newProperties = { ...properties };
    delete newProperties[propName];
    
    // Also remove from required if it was required
    const newRequired = requiredProps.filter(name => name !== propName);
    
    setProperties(newProperties);
    setRequiredProps(newRequired);
    
    // Notify parent after state update
    setTimeout(() => {
      onChange({
        type: 'object',
        properties: newProperties,
        required: newRequired
      });
    }, 0);
  };

  // Update a property's details
  const updateProperty = (propName, field, value) => {
    let newProperties = { ...properties };
    let newRequired = [...requiredProps];
    
    if (field === 'name' && propName !== value) {
      // Rename the property
      const renamedProperties = {};
      
      // Copy all properties, renaming the one that changed
      Object.keys(properties).forEach(key => {
        if (key === propName) {
          renamedProperties[value] = properties[key];
        } else {
          renamedProperties[key] = properties[key];
        }
      });
      
      // Update required props if this prop was required
      newRequired = requiredProps.map(name => 
        name === propName ? value : name
      );
      
      // Update expanded state
      const newExpandedProps = {};
      Object.keys(expandedProps).forEach(key => {
        if (key === propName) {
          newExpandedProps[value] = expandedProps[key];
        } else {
          newExpandedProps[key] = expandedProps[key];
        }
      });
      
      newProperties = renamedProperties;
      setProperties(renamedProperties);
      setRequiredProps(newRequired);
      setExpandedProps(newExpandedProps);
      
      // Restore focus to the input field after state update
      setTimeout(() => {
        if (nameInputRef.current) {
          nameInputRef.current.focus();
        }
      }, 0);
    } else {
      // Update a field within the property
      newProperties[propName] = {
        ...newProperties[propName],
        [field]: value
      };
      setProperties(newProperties);
    }
    
    // Notify parent after state update
    setTimeout(() => {
      onChange({
        type: 'object',
        properties: newProperties,
        required: newRequired
      });
    }, 0);
  };

  // Toggle whether a property is required
  const toggleRequired = (propName) => {
    let newRequired;
    
    if (requiredProps.includes(propName)) {
      newRequired = requiredProps.filter(name => name !== propName);
    } else {
      newRequired = [...requiredProps, propName];
    }
    
    setRequiredProps(newRequired);
    
    // Notify parent after state update
    setTimeout(() => {
      onChange({
        type: 'object',
        properties: properties,
        required: newRequired
      });
    }, 0);
  };

  return (
    <div className="parameter-editor">
      <div className="parameter-editor-header">
        <h4>Properties</h4>
        <button 
          type="button" 
          className="add-property-button"
          onClick={addProperty}
        >
          <Plus size={16} /> Add Property
        </button>
      </div>
      
      {Object.keys(properties).length === 0 ? (
        <div className="empty-properties">
          <p>No properties defined. Click "Add Property" to create one.</p>
        </div>
      ) : (
        <div className="properties-list">
          {Object.keys(properties).map(propName => (
            <div key={propName} className="property-item">
              <div 
                className="property-header"
                onClick={() => toggleProperty(propName)}
              >
                <div className="property-name">
                  {propName}
                </div>
                <div className="property-actions">
                  {expandedProps[propName] ? (
                    <ChevronUp size={16} />
                  ) : (
                    <ChevronDown size={16} />
                  )}
                </div>
              </div>
              
              {expandedProps[propName] && (
                <div className="property-details">
                  <div className="property-field">
                    <label>Name</label>
                    <input
                      type="text"
                      value={propName}
                      onChange={(e) => updateProperty(propName, 'name', e.target.value)}
                      ref={nameInputRef}
                    />
                  </div>
                  
                  <div className="property-field">
                    <label>Type</label>
                    <select
                      value={properties[propName].type}
                      onChange={(e) => updateProperty(propName, 'type', e.target.value)}
                    >
                      <option value="string">String</option>
                      <option value="number">Number</option>
                      <option value="boolean">Boolean</option>
                      <option value="object">Object</option>
                      <option value="array">Array</option>
                    </select>
                  </div>
                  
                  <div className="property-field">
                    <label>Description</label>
                    <input
                      type="text"
                      value={properties[propName].description || ''}
                      onChange={(e) => updateProperty(propName, 'description', e.target.value)}
                      placeholder="Describe this property"
                    />
                  </div>
                  
                  <div className="property-field checkbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={requiredProps.includes(propName)}
                        onChange={() => toggleRequired(propName)}
                      />
                      Required
                    </label>
                  </div>
                  
                  <button
                    type="button"
                    className="remove-property-button"
                    onClick={() => removeProperty(propName)}
                  >
                    <Trash2 size={14} /> Remove Property
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ParameterEditor;
