import React, { useState } from 'react';
import { Edit, Trash2, ChevronDown, ChevronUp } from 'react-feather';

const ToolsList = ({ tools, onEdit, onDelete }) => {
  const [expandedParams, setExpandedParams] = useState({});

  const toggleParameters = (toolId) => {
    setExpandedParams(prev => ({
      ...prev,
      [toolId]: !prev[toolId]
    }));
  };

  if (tools.length === 0) {
    return (
      <div className="empty-state">
        <p>No tools available. Click "Add New Tool" to create one.</p>
      </div>
    );
  }

  return (
    <div className="tools-list">
      {tools.map(tool => (
        <div className="tool-card" key={tool.id}>
          <div className="tool-header">
            <div className="tool-title-container">
              <h3 className="tool-title">{tool.name}</h3>
              {tool.is_custom && (
                <span className="tool-badge custom">Custom</span>
              )}
              {!tool.is_custom && (
                <span className="tool-badge builtin">Built-in</span>
              )}
            </div>
            <div className="tool-actions">
              <button 
                className="icon-button edit"
                onClick={() => onEdit(tool)}
                aria-label="Edit tool"
              >
                <Edit size={18} />
              </button>
              <button 
                className="icon-button delete"
                onClick={() => onDelete(tool.id)}
                aria-label="Delete tool"
              >
                <Trash2 size={18} />
              </button>
            </div>
          </div>
          <div className="tool-body">
            <p className="tool-description">{tool.description || 'No description provided.'}</p>
            <div className="tool-function">
              <small className="text-muted">ID: {tool.function_name}</small>
            </div>
            
            {tool.is_custom && tool.server_url && (
              <div className="tool-server">
                <small className="text-muted">Server: {tool.server_url}</small>
              </div>
            )}
            
            <div className="parameters-container">
              <div 
                className="parameters-header"
                onClick={() => toggleParameters(tool.id)}
              >
                <h4 className="parameters-title">Parameters</h4>
                {expandedParams[tool.id] ? (
                  <ChevronUp size={16} />
                ) : (
                  <ChevronDown size={16} />
                )}
              </div>
              {expandedParams[tool.id] && (
                <pre className="parameters-content">
                  {JSON.stringify(tool.parameters, null, 2)}
                </pre>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ToolsList;
