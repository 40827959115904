// Debug store for correlation
const debugStore = new Map();

// Check if we're in development environment
const isDevelopment = process.env.NODE_ENV === 'development';

// Debug proxy for fetch
export function createDebugFetch() {
    // Log environment information
    console.error('=== Debug Environment ===');
    console.error('NODE_ENV:', process.env.NODE_ENV);
    console.error('API URL:', process.env.REACT_APP_API_URL);
    
    const originalFetch = window.fetch;
    
    window.fetch = async function(...args) {
        const debugInfo = {
            timestamp: new Date().toISOString(),
            url: args[0],
            options: args[1],
            requestId: generateRequestId()
        };

        // Log request details
        console.error('=== Debug Fetch Request ===');
        console.error('Request ID:', debugInfo.requestId);
        console.error('URL:', debugInfo.url);
        console.error('Method:', debugInfo.options?.method || 'GET');
        console.error('Headers:', debugInfo.options?.headers);

        // If this is a form submission, log the form data
        if (debugInfo.options?.body instanceof FormData) {
            console.error('=== Form Data ===');
            const formData = debugInfo.options.body;
            const formEntries = {};
            formData.forEach((value, key) => {
                if (key === 'card_token' || key === 'cardToken') {
                    formEntries[key] = value ? `${value.substring(0, 10)}...` : 'None';
                } else {
                    formEntries[key] = value;
                }
            });
            console.error('Form Entries:', formEntries);
            
            // Store form data for debugging
            debugInfo.formData = formEntries;
        }

        // Store debug info
        debugStore.set(debugInfo.requestId, debugInfo);

        try {
            // Make the actual request
            const response = await originalFetch.apply(this, args);
            
            // Clone the response so we can read the body
            const clonedResponse = response.clone();
            
            // Try to parse response as JSON
            let responseData;
            try {
                responseData = await clonedResponse.json();
            } catch (e) {
                responseData = await clonedResponse.text();
            }

            // Update debug info with response
            debugInfo.response = {
                status: response.status,
                headers: Object.fromEntries(response.headers.entries()),
                data: responseData
            };
            console.error('=== Debug Fetch Response ===');
            console.error('Request ID:', debugInfo.requestId);
            console.error('Status:', response.status);
            console.error('Response:', responseData);

            // Update stored debug info
            debugStore.set(debugInfo.requestId, debugInfo);

            return response;
        } catch (error) {
            // Log error and update debug info
            console.error('=== Debug Fetch Error ===');
            console.error('Request ID:', debugInfo.requestId);
            console.error('Error:', error);
            
            debugInfo.error = {
                message: error.message,
                stack: error.stack
            };
            debugStore.set(debugInfo.requestId, debugInfo);
            
            throw error;
        }
    };
}

// Debug proxy for Helcim.js
export function createHelcimDebugProxy() {
    // Store original callback
    const originalCallback = window.helcimCallback;
    
    window.helcimCallback = function(response) {
        const debugInfo = {
            timestamp: new Date().toISOString(),
            requestId: generateRequestId(),
            helcimResponse: response
        };

        console.error('=== Debug Helcim.js Callback ===');
        console.error('Request ID:', debugInfo.requestId);
        console.error('Response:', response);

        // Log DOM state
        const helcimResults = document.getElementById('helcimResults');
        if (helcimResults) {
            const inputs = helcimResults.querySelectorAll('input[type="hidden"]');
            const helcimData = {};
            inputs.forEach(input => {
                if (input.name === 'cardToken') {
                    helcimData[input.name] = input.value ? `${input.value.substring(0, 10)}...` : 'None';
                } else {
                    helcimData[input.name] = input.value;
                }
            });
            console.error('Helcim Results DOM:', helcimData);
            debugInfo.helcimResultsData = helcimData;
        }

        // Store debug info
        debugStore.set(debugInfo.requestId, debugInfo);

        // Call original callback
        return originalCallback(response);
    };
}

// Debug proxy for form submission
export function createFormDebugProxy(form) {
    const debugInfo = {
        timestamp: new Date().toISOString(),
        requestId: generateRequestId(),
        formId: form.id,
        formAction: form.action
    };

    // Log initial form state
    console.error('=== Debug Form State ===');
    console.error('Request ID:', debugInfo.requestId);
    console.error('Form ID:', form.id);
    console.error('Form Action:', form.action);

    // Create MutationObserver for form
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.type === 'childList' || mutation.type === 'attributes') {
                const formData = new FormData(form);
                const formEntries = {};
                formData.forEach((value, key) => {
                    if (key === 'card_token' || key === 'cardToken') {
                        formEntries[key] = value ? `${value.substring(0, 10)}...` : 'None';
                    } else {
                        formEntries[key] = value;
                    }
                });
                console.error('=== Form Updated ===');
                console.error('Request ID:', debugInfo.requestId);
                console.error('Mutation Type:', mutation.type);
                console.error('Form Data:', formEntries);
                
                debugInfo.formUpdates = debugInfo.formUpdates || [];
                debugInfo.formUpdates.push({
                    timestamp: new Date().toISOString(),
                    mutationType: mutation.type,
                    formData: formEntries
                });
            }
        });
    });

    // Start observing
    observer.observe(form, {
        childList: true,
        attributes: true,
        subtree: true
    });

    // Store debug info
    debugStore.set(debugInfo.requestId, debugInfo);

    return observer;
}

// Helper to generate request IDs
function generateRequestId() {
    return 'debug-' + Math.random().toString(36).substr(2, 9);
}

// Get debug info
export function getDebugInfo(requestId) {
    return debugStore.get(requestId);
}

// Clear old debug info
export function clearOldDebugInfo() {
    const oneHourAgo = new Date(Date.now() - 60 * 60 * 1000);
    debugStore.forEach((value, key) => {
        if (new Date(value.timestamp) < oneHourAgo) {
            debugStore.delete(key);
        }
    });
}

// Initialize all debug proxies
export function initializeDebugProxies() {
    console.error('=== Initializing Debug Proxies ===');
    console.error('Environment:', process.env.NODE_ENV);
    
    // Force console.error to always be enabled
    const originalConsoleError = console.error;
    console.error = function() {
        // Ensure logging is always enabled
        const args = Array.from(arguments);
        originalConsoleError.apply(console, args);
        
        // Also store in localStorage for persistence
        const debugInfo = JSON.parse(localStorage.getItem('helcimDebugInfo') || '{}');
        debugInfo.consoleLog = debugInfo.consoleLog || [];
        debugInfo.consoleLog.push({
            timestamp: new Date().toISOString(),
            message: args.map(arg => 
                typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
            ).join(' ')
        });
        localStorage.setItem('helcimDebugInfo', JSON.stringify(debugInfo));
    };

    // Always create debug fetch in development, or if explicitly enabled in production
    if (isDevelopment || localStorage.getItem('enableDebugProxies') === 'true') {
        console.error('Debug proxies enabled');
        createDebugFetch();
        createHelcimDebugProxy();
        
        // Find and proxy the payment form
        const form = document.getElementById('helcimForm');
        if (form) {
            createFormDebugProxy(form);
        }
    } else {
        console.error('Debug proxies disabled in production. Set localStorage.enableDebugProxies="true" to enable.');
    }
}
