import React, { useState, useEffect, useCallback } from 'react';
import PageContainer from '../components/PageContainer';
import AssistantList from '../components/assistants/AssistantList';
import { API_BASE_URL } from '../config/api';
import { useSnackbar } from 'notistack';

const Assistants = () => {
  const [assistants, setAssistants] = useState([]);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const fetchAssistants = useCallback(async () => {
    try {
      const response = await fetch(API_BASE_URL ? `${API_BASE_URL}/api/logical-assistants/list` : '/api/logical-assistants/list', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Server response:', response.status, errorText);
        throw new Error(`Server error: ${response.status}`);
      }
      
      const data = await response.json();
      setAssistants(data.assistants || []);
    } catch (error) {
      enqueueSnackbar('Failed to load assistants', { variant: 'error' });
      console.error('Error fetching assistants:', error);
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    fetchAssistants();
  }, [fetchAssistants]);

  return (
    <PageContainer
      loading={loading}
      loadingMessage="Loading assistants..."
      title="Assistants"
      subtitle="Manage Your AI Workforce"
    >
      <AssistantList 
        assistants={assistants} 
        loading={loading}
        onUpdate={fetchAssistants}
      />
    </PageContainer>
  );
};

export default Assistants;
