import React, { useState, useEffect } from 'react';
import { Tool, Code, Sliders, Server, Trash2, Plus } from 'react-feather';
import ParameterEditor from './ParameterEditor';

const ToolForm = ({ 
  initialData = null, 
  onSubmit, 
  onCancel, 
  successMessage = null,
  errorMessage = null,
  isAdmin = false
}) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    type: 'function',
    function_name: '',
    parameters: '{"type":"object","properties":{},"required":[]}',
    is_custom: false,
    server_url: '',
    secret_token: '',
    timeout: 30,
    is_async: false,
    headers: {}
  });

  const [editorMode, setEditorMode] = useState('visual'); // 'visual' or 'json'
  const [parsedParameters, setParsedParameters] = useState({
    type: 'object',
    properties: {},
    required: []
  });

  // Initialize form with data if editing
  useEffect(() => {
    if (initialData) {
      const initialFormData = {
        name: initialData.name,
        description: initialData.description || '',
        type: initialData.type,
        function_name: initialData.function_name,
        parameters: JSON.stringify(initialData.parameters, null, 2),
        is_custom: initialData.is_custom || false,
        server_url: initialData.server_url || '',
        secret_token: initialData.secret_token || '',
        timeout: initialData.timeout || 30,
        is_async: initialData.is_async || false,
        headers: initialData.headers || {}
      };
      
      setFormData(initialFormData);
      
      // Parse parameters for visual editor
      try {
        setParsedParameters(initialData.parameters);
      } catch (error) {
        console.error('Error parsing parameters:', error);
      }
    }
  }, [initialData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Create updated form data
    const updatedFormData = {
      ...formData,
      [name]: value
    };
    
    // Auto-generate function_name from name if the name field is being changed
    if (name === 'name') {
      // Convert to snake_case: lowercase, replace spaces with underscores, remove special chars
      const functionName = value
        .toLowerCase()
        .replace(/[^\w\s]/g, '') // Remove special characters
        .replace(/\s+/g, '_');   // Replace spaces with underscores
      
      updatedFormData.function_name = functionName;
    }
    
    setFormData(updatedFormData);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked
    });
  };

  const handleParametersChange = (updatedParameters) => {
    // Update the parsed parameters state
    setParsedParameters(updatedParameters);
    
    // Update the formData with the stringified parameters
    setFormData({
      ...formData,
      parameters: JSON.stringify(updatedParameters, null, 2)
    });
  };

  const handleJsonParametersChange = (e) => {
    const jsonValue = e.target.value;
    setFormData({
      ...formData,
      parameters: jsonValue
    });
    
    // Try to parse the JSON to update the visual editor
    try {
      const parsed = JSON.parse(jsonValue);
      setParsedParameters(parsed);
    } catch (error) {
      // Invalid JSON, don't update parsedParameters
      console.error('Invalid JSON:', error);
    }
  };

  const handleHeadersChange = (e, key, isKey = false) => {
    const value = e.target.value;
    const headers = { ...formData.headers };
    
    if (isKey) {
      // Changing a key
      const oldValue = headers[key];
      delete headers[key];
      if (value) { // Only add if the new key is not empty
        headers[value] = oldValue || '';
      }
    } else {
      // Changing a value
      headers[key] = value;
    }
    
    setFormData({
      ...formData,
      headers
    });
  };

  const addHeader = () => {
    const headers = { ...formData.headers };
    const newKey = `header_${Object.keys(headers).length + 1}`;
    headers[newKey] = '';
    
    setFormData({
      ...formData,
      headers
    });
  };

  const removeHeader = (key) => {
    const headers = { ...formData.headers };
    delete headers[key];
    
    setFormData({
      ...formData,
      headers
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validate JSON
    try {
      const toolData = {
        ...formData,
        parameters: JSON.parse(formData.parameters)
      };
      
      onSubmit(toolData);
    } catch (error) {
      alert('Invalid JSON in parameters field. Please check your syntax.');
    }
  };

  return (
    <section className="tools-section">
      <h2 className="section-header">
        <Tool style={{ color: 'white', width: '1.2rem', height: '1.2rem' }} />
        {initialData ? 'Edit Tool' : 'Add New Tool'}
      </h2>
      
      {successMessage && (
        <div className="save-message success">
          {successMessage}
        </div>
      )}
      
      {errorMessage && (
        <div className="save-message error">
          {errorMessage}
        </div>
      )}
      
      <form onSubmit={handleSubmit}>
        {/* Tool Type Selector */}
        <div className="tool-type-selector">
          <div 
            className={`tool-type-option ${!formData.is_custom ? 'selected' : ''} ${!isAdmin ? 'disabled' : ''}`}
            onClick={() => {
              // Only allow admins to select built-in tools
              // For existing tools, don't allow changing from built-in to custom if not admin
              if (isAdmin || (initialData && !initialData.is_custom && !formData.is_custom)) {
                setFormData({...formData, is_custom: false});
              }
            }}
          >
            <input 
              type="radio" 
              name="is_custom" 
              checked={!formData.is_custom} 
              onChange={() => {
                if (isAdmin || (initialData && !initialData.is_custom && !formData.is_custom)) {
                  setFormData({...formData, is_custom: false});
                }
              }}
              disabled={!isAdmin && !(initialData && !initialData.is_custom)}
            />
            <div className="tool-type-option-content">
              <div className="tool-type-option-title">
                Built-in Tool
                {!isAdmin && <span className="admin-only-badge">Admin Only</span>}
              </div>
              <div className="tool-type-option-description">
                Use a pre-built tool provided by SalesOptAI
              </div>
            </div>
          </div>
          
          <div 
            className={`tool-type-option ${formData.is_custom ? 'selected' : ''}`}
            onClick={() => {
              // Don't allow changing from built-in to custom if editing a built-in tool and not admin
              if (!(initialData && !initialData.is_custom && !isAdmin)) {
                setFormData({...formData, is_custom: true});
              }
            }}
          >
            <input 
              type="radio" 
              name="is_custom" 
              checked={formData.is_custom} 
              onChange={() => {
                if (!(initialData && !initialData.is_custom && !isAdmin)) {
                  setFormData({...formData, is_custom: true});
                }
              }}
              disabled={initialData && !initialData.is_custom && !isAdmin}
            />
            <div className="tool-type-option-content">
              <div className="tool-type-option-title">Custom API Tool</div>
              <div className="tool-type-option-description">
                Connect to your own API endpoint
              </div>
            </div>
          </div>
        </div>
        
        {/* Basic Tool Information */}
        <div className="input-group">
          <label htmlFor="name">Tool Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Enter tool name"
            required
          />
        </div>
        
        <div className="input-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            placeholder="Enter tool description"
            rows="2"
          />
        </div>
        
        {/* Server Settings for Custom Tools */}
        {formData.is_custom && (
          <div className="server-settings">
            <h4>
              <Server size={16} style={{ marginRight: '0.5rem' }} />
              Server Settings
            </h4>
            
            <div className="input-group">
              <label htmlFor="server_url">Server URL</label>
              <input
                type="url"
                id="server_url"
                name="server_url"
                value={formData.server_url}
                onChange={handleInputChange}
                placeholder="https://api.example.com/endpoint"
                required={formData.is_custom}
              />
            </div>
            
            <div className="input-group">
              <label htmlFor="secret_token">Secret Token (Optional)</label>
              <input
                type="password"
                id="secret_token"
                name="secret_token"
                value={formData.secret_token}
                onChange={handleInputChange}
                placeholder="Enter secret token"
              />
            </div>
            
            <div className="input-group">
              <label htmlFor="timeout">Timeout (seconds)</label>
              <input
                type="number"
                id="timeout"
                name="timeout"
                value={formData.timeout}
                onChange={handleInputChange}
                min="1"
                max="300"
              />
            </div>
            
            <div className="toggle-switch">
              <label htmlFor="is_async">Asynchronous Execution</label>
              <label className="toggle-switch-control">
                <input
                  type="checkbox"
                  id="is_async"
                  name="is_async"
                  checked={formData.is_async}
                  onChange={handleCheckboxChange}
                />
                <span className="toggle-slider"></span>
              </label>
            </div>
            
            {/* HTTP Headers */}
            <div className="http-headers">
              <h4>HTTP Headers (Optional)</h4>
              
              {Object.keys(formData.headers).length > 0 ? (
                Object.keys(formData.headers).map(key => (
                  <div key={key} className="header-row">
                    <div className="input-group">
                      <input
                        type="text"
                        value={key}
                        onChange={(e) => handleHeadersChange(e, key, true)}
                        placeholder="Header name"
                      />
                    </div>
                    <div className="input-group">
                      <input
                        type="text"
                        value={formData.headers[key]}
                        onChange={(e) => handleHeadersChange(e, key, false)}
                        placeholder="Header value"
                      />
                    </div>
                    <button
                      type="button"
                      className="remove-header-button"
                      onClick={() => removeHeader(key)}
                    >
                      <Trash2 size={14} />
                    </button>
                  </div>
                ))
              ) : (
                <div className="empty-headers">
                  <p>No headers defined</p>
                </div>
              )}
              
              <button
                type="button"
                className="add-property-button"
                onClick={addHeader}
              >
                <Plus size={16} /> Add Header
              </button>
            </div>
          </div>
        )}
        
        {/* Parameters Editor */}
        <div className="input-group">
          <label>
            Parameters
            <small style={{ marginLeft: '0.5rem', opacity: 0.7 }}>
              Follow OpenAI and Vapi standards
            </small>
          </label>
          
          <div className="editor-toggle">
            <div 
              className={`editor-toggle-option ${editorMode === 'visual' ? 'active' : ''}`}
              onClick={() => setEditorMode('visual')}
            >
              <Sliders size={14} style={{ marginRight: '0.5rem' }} />
              Visual Editor
            </div>
            <div 
              className={`editor-toggle-option ${editorMode === 'json' ? 'active' : ''}`}
              onClick={() => setEditorMode('json')}
            >
              <Code size={14} style={{ marginRight: '0.5rem' }} />
              JSON Editor
            </div>
          </div>
          
          {editorMode === 'visual' ? (
            <ParameterEditor 
              parameters={parsedParameters}
              onChange={handleParametersChange}
            />
          ) : (
            <textarea
              id="parameters"
              name="parameters"
              value={formData.parameters}
              onChange={handleJsonParametersChange}
              className="parameters-textarea"
              placeholder='{"type": "object", "properties": {...}, "required": [...]}'
              required
            />
          )}
        </div>
        
        <div className="button-container">
          <button type="submit" className="primary-button">
            {initialData ? 'Update Tool' : 'Add Tool'}
          </button>
          <button 
            type="button" 
            className="secondary-button"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </form>
    </section>
  );
};

export default ToolForm;
